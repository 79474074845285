import { Button, ButtonGroup, DeleteIcon, EditIcon, Table, TableColumns, Tooltip } from '@bp/ui-components';
import { useBpAbsencesQuery, useBpMinimalProfileQuery, useClassesQuery } from 'client/bp-graphql-client-defs';
import dayjs from 'dayjs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type ClassbookAdministrationTableProps = {
  isLoading: boolean;
  onAdd: () => void;
  onEdit: (uuid: string) => void;
  onDelete: (uuid: string) => void;
};

type ClassbookAdministrationTableType = {
  uuid: string;
  name: string;
  classes: string;
  reason: string;
  from: string;
  until: string;
  comment: string;
  isExcused: boolean;
  ownerUuid: string;
};

export const ClassbookAdministrationTable = ({
  isLoading,
  onAdd,
  onEdit,
  onDelete,
}: ClassbookAdministrationTableProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organization = pimAuthClaims.getOrganization();
  const eventsContext = useMemoizedCacheTag('EVENT');
  const profileContext = useMemoizedCacheTag('PROFILE');
  const classesContext = useMemoizedCacheTag('CLASS');

  const [{ data: absenceData }] = useBpAbsencesQuery({
    context: eventsContext,
  });

  const [{ data: profileData }] = useBpMinimalProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: organization.uuid,
        },
      },
    },
    context: profileContext,
  });

  const [{ data: classesData }] = useClassesQuery({
    variables: { where: { organization: { uuid: organization.uuid } } },
    context: classesContext,
  });

  const absences: ClassbookAdministrationTableType[] = useMemo(() => {
    return (
      absenceData?.absences
        .filter((absence) => {
          const profile = profileData?.profiles.find((p) => p.uuid === absence.owner.uuid);
          return profile?.organizationRoles.includes('STUDENT');
        })
        .map((absence) => {
          const profile = profileData?.profiles.find((p) => p.uuid === absence.owner.uuid);
          const classes = classesData?.classes
            .filter((c) => c.membersConnection.edges.some((e) => e.node.uuid === profile?.uuid))
            .map((c) => c.shortName)
            .sort((a, b) => {
              return a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base',
              });
            })
            .join(', ');

          return {
            uuid: absence.uuid,
            name: profile?.displayName ?? '',
            classes: classes ?? '',
            reason: absence.reason?.reason ?? '',
            from: dayjs(absence.startTime).format('DD.MM.YY - HH:mm'),
            until: dayjs(absence.endTime).format('DD.MM.YY - HH:mm'),
            comment: absence.comment ?? '',
            isExcused: absence.excused ?? false,
            ownerUuid: absence.owner.uuid,
          };
        }) ?? []
    );
  }, [absenceData, profileData?.profiles, classesData?.classes]);

  const columns: TableColumns<ClassbookAdministrationTableType>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: t('common.name'),
      size: 225,
    },
    {
      id: 'class',
      accessorKey: 'class',
      header: t('classes.title', { count: 2 }),
      cell: ({ row }) => {
        return (
          <Tooltip
            triggerStyle={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden', width: 'fit-content' }}
            content={row.original.classes}
          >
            {row.original.classes}
          </Tooltip>
        );
      },
      size: 60,
    },
    {
      id: 'reason',
      accessorKey: 'reason',
      header: t('absenceReasons.title', { context: 'short' }),
      size: 175,
    },
    {
      id: 'from',
      accessorKey: 'from',
      header: t('common.from'),
    },
    {
      id: 'until',
      accessorKey: 'until',
      header: t('common.until'),
    },
    {
      id: 'comment',
      accessorKey: 'comment',
      header: t('common.comment'),
      size: 250,
    },
    {
      id: 'isExcused',
      accessorKey: 'isExcused',
      header: t('absences.isExcused'),
      type: 'boolean',
      size: 75,
    },
  ];

  return (
    <Table<ClassbookAdministrationTableType>
      data={absences}
      columns={columns}
      showBorderRadius
      showShadow
      isOnWhite={false}
      showActionBar
      actionBarSettings={{ showAddButton: true }}
      onAddClick={() => onAdd()}
      lastColWidth='68px'
      lastCol={(row) => {
        return (
          <ButtonGroup>
            <Button
              hierarchy='secondary'
              type='button'
              onClick={() => onEdit(row.original.uuid)}
              icon={<EditIcon className='small' />}
            />
            <Button
              hierarchy='secondary'
              type='button'
              onClick={() => onDelete(row.original.uuid)}
              icon={<DeleteIcon className='small' color='var(--color-error)' />}
            />
          </ButtonGroup>
        );
      }}
      loading={isLoading}
    />
  );
};
