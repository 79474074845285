import React, { useMemo } from 'react';
import { Button, ImportIcon, Table, TableColumns } from '@bp/ui-components';
import { ProcuratGroup } from './hooks/useProcuratGroups';
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table';
import { niceDate } from '../../utils/dateCalculations';
import { useTranslation } from 'react-i18next';

type ProcuratGroupsTableDataType = ProcuratGroup & { modificationDate: string };
type ProcuratClassesTableProps = {
  data: ProcuratGroupsTableDataType[];
  rowSelection: RowSelectionState;
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
  handleImport: () => void;
};

export const ProcuratGroupsTable = ({
  data, // procurat Gruppen
  rowSelection,
  onRowSelectionChange,
  handleImport,
}: ProcuratClassesTableProps) => {
  const { t } = useTranslation();

  const groupColumns: TableColumns<ProcuratGroupsTableDataType>[] = useMemo(() => {
    return [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        canExpand: true,
      },
      {
        id: 'lastImport',
        accessorKey: 'lastImport',
        header: t('common.lastUpdated'),
        cell: (cell) => {
          return niceDate(cell.row.original.modificationDate, 'short', 'short');
        },
        type: 'string',
        canExpand: true,
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: t('common.imported'),
        accessorFn: (item) => !!item.modificationDate, // if it has a date, it is already imported
        type: 'boolean',
        size: 150,
      },
    ];
  }, [t]);

  return (
    <>
      <Table<ProcuratGroupsTableDataType>
        showActionBar
        actionBarSettings={{
          showSelectedRowsCount: true,
          showExpertFilter: false,
          globalFilterPlaceholder: t('common.name'),
          extendedActionsRight: (
            <Button
              hierarchy='tertiary'
              icon={<ImportIcon />}
              onClick={handleImport}
              disabled={Object.keys(rowSelection).length < 1}
            >
              {t('institution.importGroups')}
            </Button>
          ),
        }}
        columns={groupColumns}
        data={data}
        onRowSelectionChange={onRowSelectionChange}
        rowSelection={rowSelection}
        canScroll={true}
        showSelect={true}
      />
    </>
  );
};
