import { GridRow, LazyLoader, showToast, useDefaultSelecting } from '@bp/ui-components';
import { BpCard } from '../BpCard/BpCard';
import { ProcuratGroupsTable } from './ProcuratGroupsTable';
import { useProcuratGroups } from './hooks/useProcuratGroups';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useGroupAsCourseQuery } from '../../client/bp-graphql-client-defs';
import { backendApi } from '../../utils/backendApi';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';

export const ProcuratGroups = () => {
  const { organization } = useContext(OrganizationConfigContext);
  const { t } = useTranslation();
  const { groups, isFetching, refetchGroups } = useProcuratGroups();
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const groupsContext = useMemoizedCacheTag('GROUP');
  const [{ data: groupsData }, reloadBpGroups] = useGroupAsCourseQuery({
    variables: { where: { organization: { uuid: organization?.uuid } } },
    context: groupsContext,
  });

  const tableData = useMemo(() => {
    return (
      groups?.personGroups?.map((group) => {
        const bpGroup = groupsData?.groups.find((c) => !!c.foreignRefId?.includes(group.id.toString()));
        return {
          ...group,
          modificationDate: bpGroup?.updatedAt ? bpGroup?.updatedAt : bpGroup?.createdAt ? bpGroup?.createdAt : null,
        };
      }) ?? []
    );
  }, [groups, groupsData]);

  const [importing, setImporting] = useState(false);

  const handleImport = async () => {
    showToast(t('procuratImport.importStarted'), {
      type: 'info',
      theme: 'colored',
    });

    setImporting(true);

    try {
      const selectedGroups = tableData.filter((_, index) => Object.keys(rowSelection).includes(index.toString()));
      const importData = selectedGroups.map((group) => ({ id: group.id }));

      const resp = await backendApi.put('/procurat/groups/import', JSON.stringify(importData));

      if (resp.ok) {
        await Promise.all([reloadBpGroups({ requestPolicy: 'network-only' }), refetchGroups()]);
        showSuccessToast(t('procuratImport.importSuccess'));
        onRowSelectionChange({});
      }

      if (!resp.ok) {
        showErrorToast({ graphQLErrors: [], name: '', message: resp.statusText });
      }
    } catch (error) {
      showErrorToast({
        graphQLErrors: [],
        name: '',
        message: error instanceof Error ? error.message : 'Import failed',
      });
    } finally {
      setImporting(false);
    }
  };

  return (
    <GridRow>
      <BpCard header={{ headline: t('groups.title') }}>
        {(isFetching || importing) && <LazyLoader embedded transparent />}

        {!isFetching && !importing && (
          <ProcuratGroupsTable
            data={tableData}
            rowSelection={rowSelection}
            onRowSelectionChange={onRowSelectionChange}
            handleImport={handleImport}
          />
        )}
      </BpCard>
    </GridRow>
  );
};
