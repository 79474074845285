import { Button, EmptyStateSettings, Table, TableColumns } from '@bp/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcuratOrganization } from './hooks/useProcuratGroups';

type ProcuratOrganizationTableProps = {
  data: ProcuratOrganization[];
  handleSetup: (procuratOrgaId: string) => void;
};
export const ProcuratOrganizationTable = ({ handleSetup, data }: ProcuratOrganizationTableProps) => {
  const { t } = useTranslation();
  const orgaColumns: TableColumns<ProcuratOrganization>[] = useMemo(() => {
    return [
      {
        id: 'id',
        accessorKey: 'id',
        size: 250,
      },
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        size: 200,
        canExpand: true,
      },
      {
        id: 'active',
        accessorKey: 'current',
        header: t('common.status'),
        type: 'boolean',
        size: 150,
      },
    ];
  }, [t]);

  const emptyStateSettings: EmptyStateSettings = {
    hideIcon: true,
    title: t('persons.noOrganizations'),
    padding: 's',
  };
  return (
    <Table<ProcuratOrganization>
      isOnWhite={false}
      columns={orgaColumns}
      data={data ?? []}
      hideHeader={false}
      emptyStateSettings={emptyStateSettings}
      lastColWidth={'100px'}
      lastCol={(row) => (
        <>
          <Button
            // disabled={row.original.current && !true}
            hierarchy={'secondary'}
            onClick={() => handleSetup(row.original.id)}
          >
            Aktivieren
          </Button>
        </>
      )}
    />
  );
};
