import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionBar,
  AddIcon,
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EmptyStateSettings,
  Grid,
  GridRow,
  MailIcon,
  MeetingDefaultIcon,
  Modal,
  NextcloudIcon,
  ProfileIcon,
  Row,
  Table,
  TableColumns,
  ThreemaIcon,
  useDefaultSelecting,
  useDefaultSorting,
} from '@bp/ui-components';
import {
  useBpDeleteProfileMutation,
  useBpMinimalProfileQuery,
  useBpUpdateProfileMutation,
} from '../../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import styles from './InstitutionSubpages.module.scss';
import { showErrorToast } from '../../../utils/showErrorToast';
import { showSuccessToast } from '../../../utils/showSuccessToast';
import { CreateUserForm } from '../../../components/CreateUserForm/CreateUserForm';
import { AddProfileForm } from '../../../components/AddProfileForm/AddProfileForm';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { Profile, UserStatus } from '@bp/bp-graphql-types';
import { organizationRolesAsString } from '../../../utils/organizationRolesAsString';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { BpCard } from '../../../components/BpCard/BpCard';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { ParentProfilesForm } from '../../../components/ParentProfilesForm/ParentProfilesForm';
import { OrganizationConfigContext } from '../../../context/OrganizationConfigContext';
import { GrantRolesForm } from '../../../components/GrantRolesForm/GrantRolesForm';
import { profileHasUser } from '../../../components/PersonsList/profileHasUser';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { backendApi } from '../../../utils/backendApi';
import { useConfirm } from '../../../hooks/useConfirm';

type ExternalServiceStatus = {
  __typename?: 'ExternalServicesStatus';
  enabled: boolean;
  lastSync?: string | null;
} | null;

export type ProfileTableData = {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  displayName?: string | null;
  listName?: string | null;
  uuid: string;
  title?: string | null;
  salutation?: string | null;
  prefixName?: string | null;
  birthday?: string | null;
  organizationRoles: Array<string | null>;
  userStatus: UserStatus;
  parents?: Profile[] | null;
  externalServices?: {
    __typename?: 'ExternalServices';
    bbb?: boolean | null;
    matrix?: boolean | null;
    mailbox?: ExternalServiceStatus;
    nextcloud?: ExternalServiceStatus;
    threema?: ExternalServiceStatus;
    zoom?: ExternalServiceStatus;
  } | null;
};

export const InstitutionPersonsSubpage = () => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();
  const [, updateProfile] = useBpUpdateProfileMutation();
  const [, deleteProfile] = useBpDeleteProfileMutation();
  const context = useMemoizedCacheTag('PROFILE');
  const { threemaLicense, cloudLicense, zoomLicense, mailboxLicense } = useContext(OrganizationConfigContext);

  const [profileData, setProfileData] = useState<ProfileTableData | null>(null);
  const [tableRole, setTableRole] = useState<ProfileRoles>(ProfileRoles.Student);
  const [globalFilter, setGlobalFilter] = useState<string>();

  const [{ data }, refetchProfiles] = useBpMinimalProfileQuery({
    context,
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
  });
  useEffect(() => {
    void backendApi.triggerPimImport().then(() => {
      refetchProfiles();
    });
  }, [refetchProfiles]);

  const { ConfirmationDialog: ProfileConfirmationDialog, confirm: confirmProfileDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('profiles.titleSingular'), context: 'neutrum' }),
    defaultConfirmText: t('delete.delete'),
  });

  const { ConfirmationDialog: ConnectionConfirmationDialog, confirm: confirmConnectionDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('common.connection'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });
  const { ConfirmationDialog: InviteConfirmationDialog, confirm: confirmInvite } = useConfirm({
    defaultTitle: t('profiles.sendInvitation'),
    defaultMessage: t('profiles.confirm.inviteSelectedPersons'),
    defaultConfirmText: t('common.confirm'),
  });

  const memoizedColumns = useMemo((): TableColumns<ProfileTableData>[] => {
    return [
      {
        id: 'listName',
        accessorKey: 'listName',
        header: t('persons.lastNameFirstName'),
        size: 200,
        canExpand: true,
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: t('common.email'),
        size: 200,
      },
      {
        id: 'externalServices',
        accessorKey: 'externalServices',
        size: 160,
        alignment: 'right',
        header: () => (
          <div className={styles['icon-header']}>
            <NextcloudIcon />
            <MailIcon />
            <ThreemaIcon />
            <MeetingDefaultIcon />
            <ProfileIcon />
          </div>
        ),
        cell: (cell) => {
          return (
            <div className={styles['icon-column']}>
              {cell.row.original.externalServices?.nextcloud ? (
                <div title='Nextcloud'>
                  <NextcloudIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
              {cell.row.original.externalServices?.mailbox ? (
                <div title='Mailbox'>
                  <MailIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
              {cell.row.original.externalServices?.threema ? (
                <div title='Threema'>
                  <ThreemaIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
              {cell.row.original.externalServices?.zoom ? (
                <div title='Zoom'>
                  <MeetingDefaultIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
              {profileHasUser(cell.row.original.userStatus) ? (
                <div title={t('profiles.titleSingular')}>
                  <ProfileIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
            </div>
          );
        },
        meta: {
          filterName: 'externalServices',
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedStudentColumns = useMemo((): TableColumns<ProfileTableData>[] => {
    return [
      {
        id: 'listName',
        accessorKey: 'listName',
        header: t('persons.lastNameFirstName'),
        size: 200,
        canExpand: true,
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: t('common.email'),
        size: 200,
      },
      {
        id: 'parents',
        accessorKey: 'parents',
        header: t('common.parents'),
        cell: (cell) => {
          if (cell.row.original.parents && cell.row.original.parents.length > 0) {
            return cell.row.original.parents.length;
          }
          return '-';
        },
        size: 100,
      },
      {
        id: 'externalServices',
        accessorKey: 'externalServices',
        size: 160,
        alignment: 'right',
        header: () => (
          <div className={styles['icon-header']}>
            <NextcloudIcon />
            <MailIcon />
            <ThreemaIcon />
            <MeetingDefaultIcon />
            <ProfileIcon />
          </div>
        ),
        cell: (cell) => {
          return (
            <div className={styles['icon-column']}>
              {cell.row.original.externalServices?.nextcloud ? (
                <div title='Nextcloud'>
                  <NextcloudIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
              {cell.row.original.externalServices?.mailbox ? (
                <div title='Mailbox'>
                  <MailIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
              {cell.row.original.externalServices?.threema ? (
                <div title='Threema'>
                  <ThreemaIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
              {cell.row.original.externalServices?.zoom ? (
                <div title='Zoom'>
                  <MeetingDefaultIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
              {profileHasUser(cell.row.original.userStatus) ? (
                <div title={t('profiles.titleSingular')}>
                  <ProfileIcon />
                </div>
              ) : (
                <div className={styles.spacer}></div>
              )}
            </div>
          );
        },
        meta: {
          filterName: 'externalServices',
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notImplemented = () => {
    showErrorToast({ name: 'not implemented', message: 'not implemented', graphQLErrors: [] });
  };
  const connectProfiles = (data: ProfileTableData) => {
    setProfileData(data);
    setConnectProfileModalOpen(true);
  };
  const disConnectProfiles = (_rows: Row<ProfileTableData>[]) => {
    // TODO: Implement disconnecting profiles
    notImplemented();
  };

  const addProfile = (data?: ProfileRoles) => {
    data && setTableRole(data);
    setAddProfileModalOpen(true);
  };

  const editProfile = (data?: ProfileTableData) => {
    if (data) {
      setProfileData(data);

      setTableRole(
        data?.organizationRoles &&
          ([ProfileRoles.Teacher, ProfileRoles.Student, ProfileRoles.Parent, ProfileRoles.Other].find((role) =>
            data?.organizationRoles.includes(role),
          ) ??
            ProfileRoles.Student),
      );
    }

    setAddProfileModalOpen(true);
  };

  const removeProfile = async (data?: ProfileTableData) => {
    const res = await confirmProfileDelete();
    if (res && perms?.canDeleteProfile({ uuid: data?.uuid ?? '', organization: { uuid: organizationUuid } })) {
      if (data) {
        const result = await deleteProfile({ uuid: data.uuid });
        if (result?.data?.deleteBpProfile?.entitiesDeleted === 1) {
          showSuccessToast(t('profiles.deletedProfile'));
          refetchProfiles({ requestPolicy: 'network-only' });
          return;
        }
        showErrorToast(t('common.error'));
      }
    }
  };

  const modifyParents = (data?: ProfileTableData) => {
    data && setProfileData(data);
    setParentProfileModalOpen(true);
  };

  const grantRoles = (data?: ProfileTableData) => {
    data && setProfileData(data);
    setGrantRolesModalOpen(true);
  };

  const deleteZoom = (_rows: Row<ProfileTableData>[]) => {
    // TODO: implement deleteZoom
    notImplemented();
  };

  const addZoom = (_rows: Row<ProfileTableData>[]) => {
    notImplemented();
  };

  const deleteThreema = async (rows: Row<ProfileTableData>[]) => {
    const res = await confirmConnectionDelete();
    if (res) {
      const responses = rows.map(async (row) => {
        return await updateProfile({
          where: {
            uuid: row.original.uuid,
          },
          update: {
            threema: [{ disconnect: [{}] }],
          },
        });
      });
      const awaitedResponses = await Promise.all(responses);
      const errors = awaitedResponses.map((p) => p.error);
      errors[0] && errors.length > 0 ? showErrorToast(errors[0]) : showSuccessToast(t('common.saved'));
    }
  };

  const addThreema = (_rows: Row<ProfileTableData>[]) => {
    // TODO: Implement
    notImplemented();
  };

  const deleteCloud = async (rows: Row<ProfileTableData>[]) => {
    const res = await confirmConnectionDelete();
    if (res) {
      const responses = rows.map(async (row) => {
        return await updateProfile({
          where: {
            uuid: row.original.uuid,
          },
          update: {
            nextcloud: [{ disconnect: [{}] }],
          },
        });
      });
      const awaitedResponses = await Promise.all(responses);
      const errors = awaitedResponses.map((p) => p.error);
      errors[0] && errors.length > 0 ? showErrorToast(errors[0]) : showSuccessToast(t('common.saved'));
    }
  };

  const addCloud = (_rows: Row<ProfileTableData>[]) => {
    // TODO: Implement
    notImplemented();
  };

  const deleteMailbox = (_rows: Row<ProfileTableData>[]) => {
    // TODO: Implement
    notImplemented();
  };

  const addMailbox = (_rows: Row<ProfileTableData>[]) => {
    // TODO: Implement
    notImplemented();
  };

  const inviteUsers = async (uuids: string[]) => {
    return backendApi.post(
      `/invite/`,
      JSON.stringify({
        profiles: uuids,
        baseUrl: import.meta.env.VITE_APP_REDIRECT_URI,
      }),
    );
  };

  const bulkEditMenu = (rows: Row<ProfileTableData>[]) => {
    const menu = [
      {
        label: 'Zoom Zugang anlegen',
        onClick: () => addZoom(rows),
      },
      {
        label: 'Threema Lizenz zuweisen',
        onClick: () => addThreema(rows),
      },
      {
        label: 'Mailbox.org Zugang anlegen',
        onClick: () => addMailbox(rows),
      },
      {
        onClick: () => addCloud(rows),
        label: 'Cloud Zugang anlegen',
      },
      {
        label: 'Zoom Zugang löschen',
        onClick: () => deleteZoom(rows),
      },
      {
        label: 'Threema Lizenz löschen',
        onClick: () => deleteThreema(rows),
      },
      {
        label: 'Mailbox.org Zugang Löschen',
        onClick: () => deleteMailbox(rows),
      },
      {
        label: 'Cloud Zugang Löschen',
        onClick: () => deleteCloud(rows),
      },
    ];

    return <DropdownMenu data={menu} />;
  };

  const dropDownMenu = (row: Row<ProfileTableData>) => {
    const items: DropdownMenuItem[] = [];

    const profileSubject = { uuid: row.original.uuid, organization: { uuid: organizationUuid } };

    if (perms?.canUpdateProfile(profileSubject))
      items.push({
        label: t('profiles.editProfile'),
        onClick: () => editProfile(row.original),
      });

    if (perms?.canDeleteProfile(profileSubject))
      items.push({
        label: t('profiles.deleteProfile'),
        onClick: async () => await removeProfile(row.original),
      });

    if (perms?.canUpdateProfile(profileSubject) && row.original.userStatus === UserStatus.None)
      items.push({
        label: t('common.createType', {
          type: t('profiles.titleSingular'),
        }),
        onClick: () => connectProfiles(row.original),
      });

    if (perms?.canUpdateProfile(profileSubject) && row.original.userStatus === UserStatus.Full)
      items.push({
        label: t('profiles.disconnect'),
        onClick: () => disConnectProfiles([row]),
      });

    items.push({ type: 'ruler' });

    if (perms?.canUpdateProfile(profileSubject) && row.original.userStatus === UserStatus.Invited)
      items.push({
        label: t('profiles.sendInvitation'),
        onClick: () => inviteUsers([row.original.uuid]),
      });

    items.push({ type: 'ruler' });

    if (row.original.organizationRoles?.includes(ProfileRoles.Student)) {
      items.push({
        label: t('profiles.changeParents'),
        onClick: () => modifyParents(row.original),
      });
      items.push({ type: 'ruler' });
    }

    if (perms?.isOmniAdmin() && row.original.userStatus !== UserStatus.None) {
      items.push({
        label: t('profiles.grantRoles'),
        onClick: () => grantRoles(row.original),
      });
      items.push({ type: 'ruler' });
    }

    if (zoomLicense && row.original.externalServices?.zoom)
      items.push({
        label: t('externalServices.deleteZoomAccount'),
        onClick: () => deleteZoom([row]),
      });

    if (threemaLicense && row.original.externalServices?.threema)
      items.push({
        label: t('externalServices.unassignThreemaLicense'),
        onClick: () => deleteThreema([row]),
      });

    if (mailboxLicense && row.original.externalServices?.mailbox)
      items.push({
        label: t('externalServices.deleteMailboxAccount'),
        onClick: () => deleteMailbox([row]),
      });

    if (cloudLicense && row.original.externalServices?.nextcloud)
      items.push({
        label: t('externalServices.deleteCloudAccount'),
        onClick: () => deleteCloud([row]),
      });

    if (zoomLicense && !row.original.externalServices?.zoom)
      items.push({
        label: t('externalServices.createZoomAccount'),
        onClick: () => addZoom([row]),
      });

    if (!row.original.externalServices?.threema)
      items.push({
        label: t('externalServices.assignThreemaLicense'),
        disabled: !threemaLicense,
        onClick: () => addThreema([row]),
      });

    if (!row.original.externalServices?.mailbox)
      items.push({
        label: t('externalServices.createMailboxAccount'),
        disabled: !mailboxLicense,
        onClick: () => addMailbox([row]),
      });

    if (!row.original.externalServices?.nextcloud)
      items.push({
        onClick: () => addCloud([row]),
        disabled: !cloudLicense,
        label: t('externalServices.createCloudAccount'),
      });

    return items;
  };

  const teachers = useMemo(
    () => data?.profiles.filter((p) => p.organizationRoles.includes(ProfileRoles.Teacher)) ?? [],
    [data],
  );
  const students = useMemo(() => {
    const profiles =
      (data?.profiles.filter((p) => p.organizationRoles.includes(ProfileRoles.Student)) as Array<
        Profile & {
          parents: Profile[];
        }
      >) ?? [];

    profiles.forEach(
      (p) =>
        (p.parents = p.incomingRolesConnection.edges
          .filter((edge) => edge.properties.roleNames.includes(ProfileRoles.Parent))
          .map((e) => e.node)),
    );

    return profiles;
  }, [data]);
  const parents = useMemo(
    () => data?.profiles.filter((p) => p.organizationRoles.includes(ProfileRoles.Parent)) ?? [],
    [data],
  );
  const others = useMemo(
    () => data?.profiles.filter((p) => p.organizationRoles.includes(ProfileRoles.Other)) ?? [],
    [data],
  );

  const handleAddProfileModalCLose = () => {
    refetchProfiles({ requestPolicy: 'network-only' });
    setProfileData(null);
    setAddProfileModalOpen(false);
    setTableRole(ProfileRoles.Student); // Student is the default anyway...
  };

  const teachersWithProfile = teachers.filter((t) => t.userStatus === UserStatus.Full).length;
  const studentsWithProfile = students.filter((t) => t.userStatus === UserStatus.Full).length;
  const parentsWithProfile = parents.filter((t) => t.userStatus === UserStatus.Full).length;
  const othersWithProfile = others.filter((t) => t.userStatus === UserStatus.Full).length;

  const { rowSelection: teacherSelection, onRowSelectionChange: teacherSelectionChange } = useDefaultSelecting();
  const { rowSelection: studentSelection, onRowSelectionChange: studentSelectionChange } = useDefaultSelecting();
  const { rowSelection: parentSelection, onRowSelectionChange: parentSelectionChange } = useDefaultSelecting();
  const { rowSelection: otherSelection, onRowSelectionChange: otherSelectionChange } = useDefaultSelecting();

  const { sorting: teacherSorting, onSortingChange: teacherSortingChange } = useDefaultSorting([
    { id: 'listName', desc: false },
  ]);
  const { sorting: studentSorting, onSortingChange: studentSortingChange } = useDefaultSorting([
    { id: 'listName', desc: false },
  ]);
  const { sorting: parentSorting, onSortingChange: parentSortingChange } = useDefaultSorting([
    { id: 'listName', desc: false },
  ]);
  const { sorting: otherSorting, onSortingChange: otherSortingChange } = useDefaultSorting([
    { id: 'listName', desc: false },
  ]);

  const [connectProfileModalOpen, setConnectProfileModalOpen] = useState(false);
  const [addProfileModalOpen, setAddProfileModalOpen] = useState(false);
  const [parentProfileModalOpen, setParentProfileModalOpen] = useState(false);
  const [grantRolesModalOpen, setGrantRolesModalOpen] = useState(false);

  const emptyStateSettings: EmptyStateSettings = {
    hideIcon: true,
    title: t('persons.noPerson'),
    subtitle: t('persons.noPersonHint'),
    padding: 'l',
  };

  const handleBulkInvite = async () => {
    const res = await confirmInvite();

    if (res) {
      const callbackFn = ([k, v]: [k: string, v: boolean], profiles: typeof teachers) => {
        if (v) return profiles[parseInt(k)];
        return null;
      };

      const selectedTeachers = Object.entries(teacherSelection).map((item) => callbackFn(item, teachers));
      const selectedStudents = Object.entries(studentSelection).map((item) => callbackFn(item, students));
      const selectedParents = Object.entries(parentSelection).map((item) => callbackFn(item, parents));
      const selectedOthers = Object.entries(otherSelection).map((item) => callbackFn(item, others));

      const response = await inviteUsers(
        [...selectedTeachers, ...selectedOthers, ...selectedParents, ...selectedStudents]
          .filter((p) => p?.userStatus === UserStatus.None && p?.email)
          .map((p) => p?.uuid) as string[], // could be null, but the preceeding filters rule that out
      );

      if (!response.ok) {
        showErrorToast({ message: response.statusText, name: '', graphQLErrors: [] });
      }
      if (response.ok) {
        teacherSelectionChange({});
        showSuccessToast(t('profiles.invitationSent'));
      }
    }
  };

  return (
    <BpSubpage className={styles.persons} isForbidden={!perms?.canCreateProfile({ uuid: organizationUuid })}>
      <Grid>
        {/* Actions */}
        {perms?.canListProfiles({ uuid: organizationUuid }) ? (
          <GridRow>
            <ActionBar
              marginBottom='0'
              onGlobalFilterChange={(value) => {
                setGlobalFilter(value);
              }}
              showBulkEdit
              bulkEditDropdownContent={(rows: Row<ProfileTableData>[]) => bulkEditMenu(rows)}
              extendedActionsRight={
                <>
                  <Button
                    hierarchy='tertiary'
                    icon={<MailIcon />}
                    onClick={handleBulkInvite}
                    disabled={
                      !Object.values({
                        ...teacherSelection,
                        ...parentSelection,
                        ...studentSelection,
                        ...otherSelection,
                      }).length
                    }
                  >
                    {t('profiles.sendInvitation')}
                  </Button>
                  <Dropdown
                    trigger={
                      <Button hierarchy='tertiary' icon={<AddIcon />}>
                        {t('common.addType', { type: t('persons.title') })}
                      </Button>
                    }
                  >
                    <DropdownMenu
                      data={[
                        {
                          label: t('common.addType', { type: t('rolesInOrganization.teacher_one') }),
                          onClick: () => addProfile(ProfileRoles.Teacher),
                        },
                        {
                          label: t('common.addType', { type: t('rolesInOrganization.student_one') }),
                          onClick: () => addProfile(ProfileRoles.Student),
                        },
                        {
                          label: t('common.addType', { type: t('rolesInOrganization.parent_one') }),
                          onClick: () => addProfile(ProfileRoles.Parent),
                        },
                        {
                          label: t('common.addType', { type: t('rolesInOrganization.other') }),
                          onClick: () => addProfile(ProfileRoles.Other),
                        },
                      ]}
                    />
                  </Dropdown>
                </>
              }
            />
          </GridRow>
        ) : (
          <></>
        )}

        {/*Teacher*/}
        <GridRow>
          <BpCard
            noPadding
            header={{
              headline: t('teachers.title'),
              subHeadline: `${teachersWithProfile}/${teachers.length}`,
            }}
            alwaysCollapsible
          >
            <Table<ProfileTableData>
              columns={memoizedColumns}
              data={teachers}
              emptyStateSettings={emptyStateSettings}
              showSelect
              selectOnClick
              rowSelection={teacherSelection}
              onRowSelectionChange={teacherSelectionChange}
              showSort
              sorting={teacherSorting}
              onSortingChange={teacherSortingChange}
              globalFilter={globalFilter}
              lastCol={(row) => {
                return (
                  <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
                    <DropdownMenu data={dropDownMenu(row)} />
                  </Dropdown>
                );
              }}
            />
          </BpCard>
        </GridRow>

        {/*Students*/}
        <GridRow>
          <BpCard
            noPadding
            header={{
              headline: t('students.title'),
              subHeadline: `${studentsWithProfile}/${students.length}`,
            }}
            alwaysCollapsible
          >
            <Table<ProfileTableData>
              columns={memoizedStudentColumns}
              data={students}
              emptyStateSettings={emptyStateSettings}
              customPadding='var(--spacing-6)'
              showSelect
              rowSelection={studentSelection}
              onRowSelectionChange={studentSelectionChange}
              showSort
              sorting={studentSorting}
              onSortingChange={studentSortingChange}
              globalFilter={globalFilter}
              lastCol={(row) => {
                return (
                  <Dropdown trigger={<Button hierarchy={'tertiary'} icon={<DotsVerticalIcon />} />}>
                    <DropdownMenu data={dropDownMenu(row)} />
                  </Dropdown>
                );
              }}
            />
          </BpCard>
        </GridRow>

        {/*Parents*/}
        <GridRow>
          <BpCard
            noPadding
            header={{
              headline: t('parents.title'),
              subHeadline: `${parentsWithProfile}/${parents.length}`,
            }}
            alwaysCollapsible
          >
            <Table<ProfileTableData>
              columns={memoizedColumns}
              data={parents}
              emptyStateSettings={emptyStateSettings}
              customPadding='var(--spacing-6)'
              showSelect
              rowSelection={parentSelection}
              onRowSelectionChange={parentSelectionChange}
              showSort
              sorting={parentSorting}
              onSortingChange={parentSortingChange}
              globalFilter={globalFilter}
              lastCol={(row) => {
                return (
                  <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
                    <DropdownMenu data={dropDownMenu(row)} />
                  </Dropdown>
                );
              }}
            />
          </BpCard>
        </GridRow>

        {/*Others*/}
        <GridRow>
          <BpCard
            noPadding
            header={{
              headline: t('others.title'),
              subHeadline: `${othersWithProfile}/${others.length}`,
            }}
            alwaysCollapsible
          >
            <Table<ProfileTableData>
              columns={memoizedColumns}
              data={others}
              emptyStateSettings={emptyStateSettings}
              customPadding='var(--spacing-6)'
              showSelect
              rowSelection={otherSelection}
              onRowSelectionChange={otherSelectionChange}
              showSort
              sorting={otherSorting}
              onSortingChange={otherSortingChange}
              globalFilter={globalFilter}
              lastCol={(row) => {
                return (
                  <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
                    <DropdownMenu data={dropDownMenu(row)} />
                  </Dropdown>
                );
              }}
            />
          </BpCard>
        </GridRow>
      </Grid>
      <Modal
        title={t('profiles.editProfile')}
        isOpen={connectProfileModalOpen}
        onRequestClose={() => setConnectProfileModalOpen(false)}
        width='s'
      >
        <CreateUserForm
          email={profileData?.email ?? ''}
          uuid={profileData?.uuid ?? ''}
          setModalClosed={() => setConnectProfileModalOpen(false)}
        />
      </Modal>

      <Modal
        title={
          profileData?.uuid
            ? t('persons.editRolePerson', { role: organizationRolesAsString([tableRole]) })
            : t('persons.addRolePerson', { role: organizationRolesAsString([tableRole]) })
        }
        isOpen={addProfileModalOpen}
        onRequestClose={handleAddProfileModalCLose}
      >
        <AddProfileForm setModalClosed={handleAddProfileModalCLose} data={profileData} role={tableRole} />
      </Modal>

      <Modal
        title={t('parents.editParentsOf', { child: profileData?.displayName })}
        isOpen={parentProfileModalOpen}
        onRequestClose={() => {
          setProfileData(null);
          setParentProfileModalOpen(false);
        }}
        width='s'
      >
        <ParentProfilesForm
          childUuid={profileData?.uuid ?? '0'}
          setModalClosed={() => {
            setProfileData(null);
            setParentProfileModalOpen(false);
          }}
        />
      </Modal>

      <Modal
        title={t('profiles.grantRolesTo', { profile: profileData?.displayName })}
        isOpen={grantRolesModalOpen}
        onRequestClose={() => {
          setProfileData(null);
          setGrantRolesModalOpen(false);
        }}
      >
        <GrantRolesForm
          profile={profileData}
          setModalClosed={() => {
            setProfileData(null);
            setGrantRolesModalOpen(false);
          }}
        />
      </Modal>

      <ProfileConfirmationDialog />
      <ConnectionConfirmationDialog />
      <InviteConfirmationDialog />
    </BpSubpage>
  );
};
