import {
  Button,
  CloseIcon,
  Grid,
  GridColumn,
  GridRow,
  ImportIcon,
  LazyLoader,
  Modal,
  ModalBottomButtons,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { organizationRolesAsString } from '../../../utils/organizationRolesAsString';
import { SingleValue } from 'react-select';
import { ProcuratDepartmentPersonsTableData } from '../../Procurat/ProcuratDepartmentPersonsTable';
import { BpProfileQuery, useBpProfileQuery } from '../../../client/bp-graphql-client-defs';
import { AssignExistingProfile } from './AssignExistingProfile';
import { ProcuratPerson } from '../../Procurat/hooks/useProcuratGroups';
import { RoleSelect } from './RoleSelect';

export type ProfileQueryType = Pick<BpProfileQuery, 'profiles'>['profiles'][0];

export type GrantRoleValues = Record<string, { role: ProfileRoles; assign?: string }>;

export const ImportPersonsModal = ({
  open,
  setOpen,
  data,
  handleImport,
}: {
  open: boolean;
  setOpen: (state: boolean) => void;
  data: ProcuratDepartmentPersonsTableData[];
  handleImport: (x: GrantRoleValues) => void;
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  function findMatchingProfiles(
    profiles: ProfileQueryType[] | undefined,
    person: ProcuratPerson,
  ): ProfileQueryType[] | undefined {
    const byName = profiles?.filter((p) => p.firstName === person.firstName && p.lastName === person.lastName);
    const byMail = profiles?.filter((p) => p.email === person.email);

    return [...(byName ?? []), ...(byMail ?? [])];
  }

  /* search for BpProfiles  with matching email or Firstname lastname */
  const [profilesQuery, reFetchBpProfiles] = useBpProfileQuery({
    variables: {
      where: {
        AND: [
          {
            OR: [
              { email_IN: data.map((p) => p.email) },
              { AND: [{ lastName_IN: data.map((p) => p.lastName) }, { firstName_IN: data.map((p) => p.firstName) }] },
            ],
          },
          {
            OR: [{ foreignRefId: null }, { foreignRefId: [] }],
          },
        ],
      },
    },
    requestPolicy: 'network-only',
  });

  useEffect(() => {
    reFetchBpProfiles({ requestPolicy: 'network-only' });
  }, [data, reFetchBpProfiles]);

  // combine incoming data with matching profiles
  const _data = data.map((person) => {
    return { ...person, matching: findMatchingProfiles(profilesQuery.data?.profiles, person) ?? [] };
  });

  const dataWithMatching = _data.filter((item) => item.matching.length > 0).sort();
  const dataWithoutMatching = _data.filter((item) => item.matching.length === 0).sort();

  const initialValues: GrantRoleValues = {};
  for (const person of data) {
    initialValues[person.id.toString()] = { role: (person.roles[0] as ProfileRoles) ?? ProfileRoles.Other };
  }

  const selectOpts = useMemo(() => {
    return [ProfileRoles.Other, ProfileRoles.Student, ProfileRoles.Teacher, ProfileRoles.Parent].map(
      (r) => ({ label: organizationRolesAsString([r]), value: r }) as SelectOptionType,
    );
  }, []);

  return (
    <Modal
      isOpen={open}
      footer={
        <div>
          <Button onClick={handleClose} hierarchy='secondary' icon={<CloseIcon />}>
            {t('common.cancel')}
          </Button>
        </div>
      }
      onRequestClose={handleClose}
      shouldCloseOnEsc
      title={t('institution.importPersons')}
    >
      {profilesQuery.fetching && <LazyLoader embedded transparent />}

      {!profilesQuery.fetching && (
        <Formik<GrantRoleValues> initialValues={initialValues} onSubmit={handleImport}>
          {({ setFieldValue, values, isSubmitting, errors }) => (
            <Form>
              <Grid>
                <GridRow>
                  <GridColumn width={6}>&nbsp;</GridColumn>
                  <GridColumn width={2}>
                    <div>{t('procuratImport.chooseRole')}</div>
                    <Select
                      options={selectOpts}
                      isSearchable={true}
                      onChange={async (event) => {
                        const role = (event as SingleValue<SelectOptionType>)?.value as ProfileRoles;
                        await Promise.all(
                          data.map((person) =>
                            setFieldValue(person.id.toString(), {
                              ...values[person.id],
                              role,
                            }),
                          ),
                        );
                      }}
                      name={'roleSelect'}
                      dense
                      placeholder={t('procuratImport.roleForAll')}
                    />
                  </GridColumn>
                  <GridColumn width={3}>&nbsp;</GridColumn>
                </GridRow>
                <GridRow spacingTop='none'>
                  <GridColumn width={3}>&nbsp;</GridColumn>
                  <GridColumn width={3}> {t('procuratImport.previouslyImportedWithRole')}</GridColumn>
                  <GridColumn width={2}>&nbsp;</GridColumn>
                  <GridColumn width={3} align={'start'} justify={'center'}>
                    {t('procuratImport.assignExistingProfile')}
                  </GridColumn>
                </GridRow>
                {dataWithMatching.map((person) => (
                  <GridRow spacingTop='none' key={person.id}>
                    <GridColumn width={3} align={'start'} justify={'center'}>
                      {person.firstName} {person.lastName}
                    </GridColumn>
                    <GridColumn width={3}> &nbsp;</GridColumn>
                    <GridColumn width={2} align={'start'} justify={'center'}>
                      <RoleSelect selectOpts={selectOpts} person={person} />
                    </GridColumn>
                    <GridColumn width={3} align={'start'} justify={'center'}>
                      <AssignExistingProfile
                        person={person}
                        handleSelect={(uuid: string) =>
                          setFieldValue(person.id.toString(), {
                            ...values[person.id],
                            assign: uuid,
                            role: profilesQuery.data?.profiles.find((p) => p.uuid === uuid)?.organizationRoles ?? '',
                          })
                        }
                      />
                    </GridColumn>
                  </GridRow>
                ))}
              </Grid>
              <hr />
              <Grid>
                {dataWithoutMatching.map((person) => {
                  return (
                    <GridRow spacingTop='none' key={person.id}>
                      <GridColumn width={3} align={'start'} justify={'center'}>
                        {person.firstName} {person.lastName}
                      </GridColumn>
                      <GridColumn width={3} align={'start'} justify={'center'}>
                        {selectOpts.find((v) => person.roles.includes(v.value as string))?.label}
                      </GridColumn>
                      <GridColumn width={2} align={'start'} justify={'center'}>
                        <RoleSelect selectOpts={selectOpts} person={person} />
                      </GridColumn>
                      <GridColumn width={3}>&nbsp;</GridColumn>
                    </GridRow>
                  );
                })}
              </Grid>
              <ModalBottomButtons
                closeButton={{
                  callback: () => setOpen(false),
                }}
                submitButton={{ text: t('institution.importPersons'), icon: <ImportIcon /> }}
                isLoading={isSubmitting}
                errors={errors}
              />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
