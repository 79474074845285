import { Select, SelectOptionType } from '@bp/ui-components';
import { ProcuratDepartmentPersonsTableData } from '../../Procurat/ProcuratDepartmentPersonsTable';
import { useFormikContext } from 'formik';
import { SingleValue } from 'react-select';
import { ProfileRoles } from '@bp/pim-auth-constants';
import React from 'react';
import { GrantRoleValues } from './ImportPersonsModal';

export const RoleSelect = ({
  selectOpts,
  person,
}: {
  selectOpts: SelectOptionType[];
  person: ProcuratDepartmentPersonsTableData;
}) => {
  const { values, setFieldValue } = useFormikContext<GrantRoleValues>();

  return (
    <Select
      value={selectOpts.find((v) => values[person.id].role.includes(v.value as string))}
      options={selectOpts}
      isSearchable={true}
      onChange={async (event) => {
        const role = (event as SingleValue<SelectOptionType>)?.value as ProfileRoles;
        await setFieldValue(person.id.toString(), { ...values[person.id], role });
      }}
      name={'roleSelect'}
      dense
    />
  );
};
