import { useTranslation } from 'react-i18next';
import { LazyLoader } from '@bp/ui-components';
import { BpPage } from 'components/BpPage/BpPage';
import { usePermissionChecker } from 'hooks/usePermissionChecker';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { NavigationTabs, NavigationTabsType } from 'components/NavigationTabs/NavigationTabs';
import { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const ClassbookPage = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const navigate = useNavigate();
  const permissions = usePermissionChecker();
  const location = useLocation();

  const organization = pimAuthClaims.getOrganization();
  const profile = pimAuthClaims.getProfile();
  const children = pimAuthClaims.getChildProfiles();

  const shouldRedirect =
    !permissions?.canViewClassbookCoursesAndClasses(organization) && !location.pathname.includes('/absences');

  const tabs: NavigationTabsType[] = [
    ...(permissions?.canViewClassbookCoursesAndClasses(organization)
      ? [
          {
            title: t('courses.title'),
            path: '/classbook',
            pathMatchEnd: true,
          },
        ]
      : []),
    ...(permissions?.canCreateAbsenceForChildren(organization, children) ||
    permissions?.canCreateAbsenceForSelf(organization, profile)
      ? [
          {
            title: t('absences.title', { count: 2 }),
            path: 'absences',
          },
        ]
      : []),
    ...(permissions?.canViewClassbookAdministration(organization)
      ? [
          {
            title: t('classbook.administration'),
            path: 'administration',
          },
        ]
      : []),
    ...(permissions?.canViewClassbookCoursesAndClasses(organization)
      ? [
          {
            title: t('classbook.evaluation'),
            path: 'classes',
            disabled: true,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/classbook/absences');
    }
  }, [navigate, shouldRedirect]);

  return (
    <BpPage
      title={t('classbook.title', { count: 1 })}
      isForbidden={!permissions?.canViewClassbook(organization, profile, children)}
    >
      <NavigationTabs tabs={tabs} className='mb-6' />
      <Suspense fallback={<LazyLoader embedded transparent forceHeight='45vh' />}>{<Outlet />}</Suspense>
    </BpPage>
  );
};
