import { Skeleton } from '@bp/ui-components';
import { BpSubpage } from 'components/BpSubpage/BpSubpage';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { usePermissionChecker } from 'hooks/usePermissionChecker';

export const ClassbookClassesSubpage = () => {
  const permissions = usePermissionChecker();
  const organization = useAuthClaims().pimAuthClaims.getOrganization();

  return (
    <BpSubpage isForbidden={!permissions?.canViewClassbookCoursesAndClasses(organization)}>
      <br />
      <p>Hier wird es Auswertungen nach einzelnen Klassen/Schülern geben</p>
      <br />
      <Skeleton />
    </BpSubpage>
  );
};
