import { useQuery } from '@tanstack/react-query';
import { backendApi } from '../../../utils/backendApi';
import { useEffect, useState } from 'react';
import { showErrorToast } from '../../../utils/showErrorToast';

export type ProcuratOrganization = {
  id: string;
  name: string;
  aktiv: boolean;
  current: boolean;
};
export type ProcuratPerson = {
  id: number;
  familyId: number;
  nationalityId: number;
  addressId: number;
  birthCountryId: number;
  languageId: number;
  religionId: number;
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  birthPlace: string;
  allFirstNames: string;
  birthName: string;
  academicTitle: string;
  namePrefix: string;
  nobilityTitle: string;
  salutationA: string;
  salutationB: string;
  jobTitle: string;
  comment: string;
  maritalStatus: string;
  familyRole: string;
  email: string;
  deathDate: string;
};

export enum ProcuratGroupType {
  Classes = 'classes',
  SchoolYears = 'schoolYears',
  PersonGroups = 'personGroups',
  Departments = 'departments',
  Facilities = 'facilities',
  SystemYear = 'systemYear',
}

export type ProcuratGroup = {
  id: number;
  parentGroupId: number | null;
  name: string | null;
  shortName: string | null;
  type: ProcuratGroupType | null;
  grade: number | null;
  character: string | null;
  schoolYear: string | null;
  additionalType: null | string;
  sortKey: number | null;
  classes: ProcuratGroup[];
};

export const useProcuratGroups = () => {
  const { isFetching, data, error } = useQuery({
    queryKey: ['procuratGroup'],
    queryFn: async () => {
      const response = await backendApi.fetch(`/procurat/groups`);
      return await response.json();
    },
  });

  const [groups, setGroups] = useState<Record<ProcuratGroupType, ProcuratGroup[]>>();

  // fake Procurat-Group Refetch to rerender the table faster; the data won't change anyway
  const refetch = async () => setGroups(data);

  useEffect(() => {
    if (error) {
      showErrorToast({ graphQLErrors: [], message: error.cause as string, name: '' });
    }

    if (!error && !isFetching && data) {
      setGroups(data);
    }
  }, [data, error, isFetching]);

  return { groups, refetchGroups: refetch, error, isFetching };
};
