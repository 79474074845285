import { FC, useMemo } from 'react';
import { AbsenceType, Attendee, ClassbookAttendeeListItem } from './ClassbookAttendeeListItem';
import { BpEventType } from 'components/EventsList/EventsList';
import dayjs from 'dayjs';
import { useAbsenceReasonsQuery } from 'client/bp-graphql-client-defs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';

export type ClassbookAttendeeListProps = {
  event: BpEventType | null;
  attendees: Array<Attendee> | null;
  onAbsenceUpdate: (updatedAbsence: Partial<AbsenceType> & { comment?: string }) => Promise<void>;
};

export const ClassbookAttendeeList: FC<ClassbookAttendeeListProps> = ({ event, attendees, onAbsenceUpdate }) => {
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const context = useMemoizedCacheTag('ABSENCE_REASONS');

  const [{ data }] = useAbsenceReasonsQuery({
    variables: {
      where: {
        organization: { uuid: organizationUuid },
        AND: [{ internal: false }],
      },
    },
    context,
  });

  const reasons = useMemo(() => {
    return (
      data?.absenceReasons.map((r) => ({
        value: r.uuid,
        label: r.reason,
      })) ?? []
    );
  }, [data]);

  return (
    attendees &&
    event &&
    attendees.map((attendee) => {
      return (
        <ClassbookAttendeeListItem
          key={attendee.uuid}
          onAbsenceUpdate={onAbsenceUpdate}
          attendee={attendee}
          reasons={reasons}
          start={event.start}
          end={dayjs(event.start).add(Number(dayjs.duration(Number(event.duration), 's')), 's')}
        />
      );
    })
  );
};
