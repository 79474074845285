import { GridRow, LazyLoader, Select, SelectOptionType, showToast, useDefaultSelecting } from '@bp/ui-components';
import { BpCard } from '../BpCard/BpCard';
import { ProcuratPerson, useProcuratGroups } from './hooks/useProcuratGroups';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { use_ProfilesInfoQuery } from '../../client/bp-graphql-client-defs';
import { backendApi } from '../../utils/backendApi';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import { ProcuratDepartmentPersonsTable, ProcuratDepartmentPersonsTableData } from './ProcuratDepartmentPersonsTable';
import { SingleValue } from 'react-select';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { useQuery } from '@tanstack/react-query';
import { GrantRoleValues, ImportPersonsModal } from '../Modals/ProcuratImport/ImportPersonsModal';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { validate } from 'uuid';

interface ImportPersonData {
  id: number;
  role: string;
  assign?: string;
}

export const ProcuratDepartments: React.FC = () => {
  const { organization } = useContext(OrganizationConfigContext);
  const { t } = useTranslation();
  const { groups, isFetching: isGroupsFetching } = useProcuratGroups();
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  // State management
  const [importing, setImporting] = useState(false);
  const [depID, setDepID] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState<ProcuratDepartmentPersonsTableData[]>([]);

  // Queries
  const profileContext = useMemoizedCacheTag('PROFILE');
  const [{ data: profilesData }, refetchBpPersons] = use_ProfilesInfoQuery({
    variables: { where: { organization: { uuid: organization?.uuid } } },
    context: profileContext,
  });

  const {
    isFetching: personsFetching,
    data: personsData,
    error: personsFetchingError,
    refetch: refetchPersons,
  } = useQuery<ProcuratPerson[]>({
    queryKey: ['procuratPersons', depID],
    queryFn: async () => {
      if (depID === null) return [];
      const response = await backendApi.fetch(`/procurat/groupmembers/${depID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch group members');
      }
      return await response.json();
    },
    enabled: depID !== null,
  });

  // Memoized values
  const departments = useMemo(() => groups?.departments ?? [], [groups?.departments]);

  const selectData = useMemo(
    () =>
      departments.map((sy) => ({
        id: sy.id,
        label: sy.name,
      })),
    [departments],
  );

  const selectOpts = useCreateSelectOptions(selectData, 'id', 'label');

  const tableData = useMemo<ProcuratDepartmentPersonsTableData[]>(() => {
    if (!personsData) return [];

    return personsData
      .map((person) => {
        const profile = profilesData?.profiles.find((p) =>
          p.foreignRefId?.some((r) => r.includes(person.id.toString())),
        );
        const lastImport = profile?.updatedAt ?? profile?.createdAt ?? null;

        return {
          ...person,
          profile: profile?.uuid ?? 'Unknown',
          lastImport,
          imported: !!lastImport,
          roles:
            profile?.rolesConnection.edges
              .flatMap((e) => e.properties.roleNames)
              .filter((r) => Object.values(ProfileRoles).includes(r as ProfileRoles)) ?? [],
        };
      })
      .sort((a, b) => `${a.lastName},${a.firstName}`.localeCompare(`${b.lastName},${b.firstName}`));
  }, [personsData, profilesData]);

  // Effects
  useEffect(() => {
    if (depID !== null) {
      void refetchPersons();
    }
  }, [depID, refetchPersons]);

  // Handlers
  const handleSelectChange = useCallback(
    async (e: SingleValue<SelectOptionType>) => {
      onRowSelectionChange({});
      const newDepId = e?.value as number;
      setDepID(newDepId ?? null);

      if (personsFetchingError) {
        showErrorToast({
          graphQLErrors: [],
          name: '',
          message: personsFetchingError instanceof Error ? personsFetchingError.message : 'Unknown error',
        });
      }
    },
    [onRowSelectionChange, personsFetchingError],
  );

  const handleRoleSelect = useCallback(() => {
    setIsModalOpen(true);
    setSelectedPersons(tableData.filter((_, index) => Object.keys(rowSelection).includes(index.toString())));
  }, [rowSelection, tableData]);

  const handleImport = useCallback(
    async (grantRoleValues: GrantRoleValues) => {
      setIsModalOpen(false);
      showToast(t('procuratImport.importStarted'), {
        type: 'info',
        theme: 'colored',
      });

      setImporting(true);

      try {
        const selectedProfiles = tableData.filter((_, index) => Object.keys(rowSelection).includes(index.toString()));
        const importData: ImportPersonData[] = selectedProfiles.map((person) => {
          const data: ImportPersonData = {
            id: person.id,
            role: grantRoleValues[person.id].role,
          };

          if (validate(grantRoleValues[person.id].assign)) {
            data.assign = grantRoleValues[person.id].assign;
          }

          return data;
        });

        const resp = await backendApi.put(
          '/procurat/persons/import',
          JSON.stringify({
            group: depID,
            persons: importData,
          }),
        );

        if (resp.ok) {
          await Promise.all([refetchBpPersons({ requestPolicy: 'network-only' }), refetchPersons()]);
          showSuccessToast(t('procuratImport.importSuccess'));
          onRowSelectionChange({});
        } else {
          showErrorToast({ graphQLErrors: [], name: '', message: resp.statusText });
        }
      } catch (error) {
        showErrorToast({
          graphQLErrors: [],
          name: '',
          message: error instanceof Error ? error.message : 'Import failed',
        });
      } finally {
        setImporting(false);
      }
    },
    [depID, onRowSelectionChange, refetchBpPersons, refetchPersons, rowSelection, t, tableData],
  );

  const isLoading = isGroupsFetching || importing;

  return (
    <>
      <GridRow>
        <BpCard header={{ headline: t('procuratImport.department.plural') }} minHeight='50vh'>
          {isLoading && <LazyLoader embedded transparent />}

          {!isLoading && (
            <>
              <Select
                isSearchable={true}
                options={selectOpts}
                placeholder={t('procuratImport.selectDepartment')}
                onChange={(e) => handleSelectChange(e as SingleValue<SelectOptionType>)}
                name='departments'
              />

              {!personsFetching && (
                <ProcuratDepartmentPersonsTable
                  data={tableData}
                  profilesData={profilesData}
                  rowSelection={rowSelection}
                  onRowSelectionChange={onRowSelectionChange}
                  handleImport={handleRoleSelect}
                />
              )}
              {personsFetching && <LazyLoader embedded transparent />}
            </>
          )}
        </BpCard>
      </GridRow>

      <ImportPersonsModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        data={selectedPersons}
        handleImport={handleImport}
      />
    </>
  );
};
