import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectOptionType } from '@bp/ui-components';
import { MultiValue } from 'react-select';
import styles from './GroupForm.module.scss';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useFormikContext } from 'formik';
import { ProfileSelection } from '../ProfileSelection/ProfileSelection';
import {
  GroupType,
  use_BpProfileForGroupFormQuery,
  useClassesQuery,
  useClassGroupsQuery,
  useDivisionsQuery,
  useGroupAsGroupsQuery,
} from '../../client/bp-graphql-client-defs';
import { uniqueByUuid } from '../../utils/uniquByUuid';

export const GroupFormProfiles = <T extends { viewers: string[] }>() => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const groupContext = useMemoizedCacheTag('GROUP');
  const profilesContext = useMemoizedCacheTag('PROFILE');
  const classesContext = useMemoizedCacheTag('CLASS');
  const classGroupContext = useMemoizedCacheTag('CLASSGROUP');

  const { setFieldValue, values, setFieldTouched } = useFormikContext<T>();

  const [filterGroups, setFilterGroups] = useState<string[] | null>(null);

  useEffect(() => {
    // nötig, um im Existierende Klassen / Teilungen / Gruppen - Select die Klasse aus der vorherigen Seite zu übernehmen
    if ('classes' in values) setFilterGroups(values.classes as string[]);
  }, [values]);

  const [{ data: profilesPoolData }] = use_BpProfileForGroupFormQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: profilesContext,
  });

  const [{ data: groupsData }] = useGroupAsGroupsQuery({
    context: groupContext,
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
        type: GroupType.Group,
      },
    },
  });

  const [{ data: classesData }] = useClassesQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: classesContext,
  });

  const [{ data: divisionsData }] = useDivisionsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: classesContext,
  });

  const [{ data: classGroupsData }] = useClassGroupsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: classGroupContext,
  });

  const handleSelect = (event: MultiValue<SelectOptionType>) => {
    const uuids = event.map((e) => e.value as string);
    setFilterGroups(uuids.length ? uuids : null);
  };

  const selectOpts = useMemo(() => {
    const groupOpts =
      groupsData?.groups.map((g) => ({
        value: g.uuid,
        label: g.name,
        group: t('groups.title'),
      })) ?? [];

    const classOpts =
      classesData?.classes.flatMap((c) => {
        const divisions = divisionsData?.divisions.filter((d) => c.uuid === d.class.uuid);

        const result = [
          {
            value: c.uuid,
            label: `Gesamte ${t('classes.titleSingular')} ${c.shortName}`,
            group: `${t('classes.titleSingular')} ${c.shortName}`,
          },
        ];

        if (divisions?.length)
          result.push(
            ...(divisions?.flatMap((division) =>
              division.groups.map((group) => ({
                value: group.uuid,
                label: `${t('divisions.title.singular')} ${group.name}`,
                group: `${t('classes.titleSingular')} ${c.shortName}`,
              })),
            ) ?? []),
          );

        return result;
      }) ?? [];

    return [...classOpts, ...groupOpts];
  }, [classesData?.classes, divisionsData?.divisions, groupsData?.groups]);

  const courseProfiles =
    profilesPoolData?.profiles.filter((profile) => {
      return values.viewers.includes(profile.uuid);
    }) ?? [];

  const filterCallBack = (item: { uuid: string; division?: { uuid: string } }) =>
    item.division ? filterGroups?.includes(item.division.uuid) : filterGroups?.includes(item.uuid);

  const profilesFilterGroups = filterGroups
    ? [
        ...(groupsData?.groups.filter(filterCallBack).map((group) => group.viewers) ?? []),
        ...(classGroupsData?.classGroups
          .filter(filterCallBack)
          .map((classGroups) => classGroups.membersConnection.edges.map((p) => p.node)) ?? []),
        ...(classesData?.classes.filter(filterCallBack).map((cls) => {
          return cls.membersConnection.edges.map((edge) => edge.node);
        }) ?? []),
        ...(divisionsData?.divisions.map((division) => {
          return division.groups.filter(filterCallBack).map((group) => group.members);
        }) ?? []),
      ].flat(2)
    : profilesPoolData?.profiles;

  const profilesToSelect = profilesFilterGroups
    ? uniqueByUuid(profilesFilterGroups)
        ?.filter((profile) => !courseProfiles.some((cp) => cp.uuid === profile.uuid))
        ?.map((profile) => ({ value: profile.uuid, label: `${profile.selectName}` }))
    : [];

  return (
    <div className={styles['profile-selector']}>
      <Select
        className={styles.select}
        label={t('courses.allGroups')}
        name={'group'}
        isMulti
        isSearchable
        options={selectOpts}
        onChange={(event) => {
          handleSelect(event as MultiValue<SelectOptionType>);
        }}
        value={selectOpts.filter((so) => filterGroups?.includes(so.value))}
        isClearable
        menuPosition='fixed'
        hasGroups
      />
      <ProfileSelection
        items={profilesToSelect}
        selectedItems={
          courseProfiles.map((profile) => ({
            value: profile.uuid,
            label: `${profile.selectName}`,
          })) ?? []
        }
        onSelect={async (items) => {
          await setFieldValue('viewers', [...values.viewers, ...items.map((i) => i.value)]);
          await setFieldTouched('viewers', true);
        }}
        onRemove={async (items) => {
          const uuids = items.map((i) => i.value);
          await setFieldValue('viewers', [
            ...values.viewers.filter((p) => {
              return !uuids.includes(p);
            }),
          ]);
          await setFieldTouched('viewers', true);
        }}
      />
    </div>
  );
};
