import { FC, useContext, useState } from 'react';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { AbsenceReasonTable } from 'components/Absences/tables/AbsenceReasonTable';
import { AbsenceReasonModal } from 'components/Absences/modals/AbsenceReasonModal';
import { useDeleteAbsenceReasonMutation, useUpdateBildungsplattformsMutation } from 'client/bp-graphql-client-defs';
import { useConfirm } from 'hooks/useConfirm';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { AddIcon, Checkbox, Grid, GridColumn, GridRow } from '@bp/ui-components';
import { BpCard } from 'components/BpCard/BpCard';
import { OrganizationConfigContext } from 'context/OrganizationConfigContext';

export type AbsenceReason = {
  uuid: string;
  reason: string;
  onlyForRoles: string[];
  internal: boolean;
};

export const ClassbookSettingsSubpage: FC = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const permissions = usePermissionChecker();
  const context = useMemoizedCacheTag('ABSENCE_REASONS');

  const { organizationProducts, allowParentExcuses, setAllowParentExcuses } = useContext(OrganizationConfigContext);

  const [selectedReasonUuid, setSelectedReasonUuid] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [, deleteAbsence] = useDeleteAbsenceReasonMutation();
  const [, updateBildungsplattform] = useUpdateBildungsplattformsMutation();

  const bildungsplattform = organizationProducts?.find((p) => p.__typename === 'Bildungsplattform');

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('absenceReasons.title', { count: 1 }), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  async function handleDelete(uuid: string) {
    setLoading(true);
    const result = await deleteAbsence({ where: { uuid } }, context);
    if (result.error) {
      showErrorToast(result.error);
    } else {
      showSuccessToast(t('absenceReasons.reasonDeleted'));
    }
    setSelectedReasonUuid(null);
    setLoading(false);
  }

  function handleAdd() {
    setModalOpen(true);
    setSelectedReasonUuid(null);
  }

  function handleEdit(uuid: string) {
    setSelectedReasonUuid(uuid);
    setModalOpen(true);
  }

  function handleClose() {
    setModalOpen(false);
    setSelectedReasonUuid(null);
  }

  const handleAllowParentExcuses = async (value: boolean) => {
    if (bildungsplattform) {
      const resp = await updateBildungsplattform({
        update: {
          allowParentExcuses: value,
        },
        where: { uuid: bildungsplattform.uuid },
      });
      if (resp.error) {
        showErrorToast(resp.error);
      } else {
        setAllowParentExcuses(value);
        showSuccessToast(t('common.saved'));
      }
    }
  };

  return (
    <BpSubpage isForbidden={!permissions?.isOrganizationAdmin({ uuid: organizationUuid })}>
      <Grid>
        <GridRow>
          <GridColumn width={9}>
            <BpCard
              header={{
                headline: t('absenceReasons.title', { count: 2 }),
                actions: [
                  {
                    text: t('common.add'),
                    icon: <AddIcon />,
                    callback: () => handleAdd(),
                  },
                ],
              }}
              noPadding
            >
              <AbsenceReasonTable
                isLoading={loading}
                onEdit={handleEdit}
                onDelete={async (uuid) => {
                  const res = await confirmDelete();
                  if (res) handleDelete(uuid);
                }}
              />
            </BpCard>
          </GridColumn>
          <GridColumn width={3}>
            <BpCard header={{ headline: t('settings.title') }}>
              <Checkbox
                name='allow-parent-excuses'
                checked={allowParentExcuses}
                onChange={async () => {
                  await handleAllowParentExcuses(!allowParentExcuses);
                }}
                label={t('classbook.allowParentExcuses')}
              />
            </BpCard>
          </GridColumn>
        </GridRow>
      </Grid>

      <AbsenceReasonModal isOpen={modalOpen} selectedReasonUuid={selectedReasonUuid} onClose={() => handleClose()} />
      <ConfirmationDialog />
    </BpSubpage>
  );
};
