import { useTranslation } from 'react-i18next';
import { BpPage } from '../../components/BpPage/BpPage';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { BpCard } from 'components/BpCard/BpCard';
import {
  useBpProfileQuery,
  useNotificationPreferencesQuery,
  useThreemaConnectionQuery,
} from '../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { BpHeader } from '../../components/BpHeader/BpHeader';
import { LogOutIcon } from '@bp/ui-components';
import { useAuth } from 'react-oidc-context';
import { ProfileForm } from '../../components/ProfileForm/ProfileForm';
import { useState } from 'react';
import { NotificationSettingsForm } from '../../components/NotificationSettingsForm/NotificationSettingsForm';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { ChangeChildrenForm } from 'components/ChangeChildrenForm/ChangeChildrenForm';
import { AccessOverview } from 'components/AccessOverview/AccessOverview';
import { NotificationTypes } from '../../components/NotificationSettingsForm/types';
import { AccountForm } from '../../components/AccountForm/AccountForm';

export type CombinedProfile = {
  email?: string | null;
  loginEmail?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  threemaId?: string | null;
  workPass?: string | null;
  workUser?: string | null;
};

export const ProfilePage = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const childProfilesClaim = pimAuthClaims.getChildProfiles();
  const auth = useAuth();
  const context = useMemoizedCacheTag('PROFILE');
  const perms = usePermissionChecker();

  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [editAccount, setEditAccount] = useState<boolean>(false);
  const [editNotifications, setEditNotifications] = useState<boolean>(false);
  const [editChildren, setEditChildren] = useState(false);
  const [loginHint, setLoginHint] = useState<string>('');

  const [{ data }] = useBpProfileQuery({
    variables: {
      where: {
        uuid: pimAuthClaims.getProfile().uuid,
      },
    },
    context,
  });

  const [{ data: profileSettings }] = useNotificationPreferencesQuery({
    variables: { where: { uuid: pimAuthClaims.getProfile().uuid } },
    context,
  });

  const profile = data?.profiles[0];

  const [threemaData] = useThreemaConnectionQuery({
    variables: {
      where: {
        uuid: pimAuthClaims.getProfile().uuid,
      },
    },
    context,
  });

  const threemaInfo = threemaData.data?.profiles.shift()?.threemaConnection.edges[0];

  const combinedProfile: CombinedProfile = {
    ...profile,
    ...threemaInfo,
  };

  const childrenOptions: { label: string; value: string }[] = childProfilesClaim?.map((p) => ({
    label: p.displayName ?? '',
    value: p.uuid ?? '',
  }));

  const changeProfile = () => {
    auth.signinRedirect({ prompt: 'select_account consent', login_hint: loginHint });
  };

  return (
    <BpPage hideHeader title={t('profiles.titleSingular')} isForbidden={false}>
      <BpHeader
        size='l'
        headline={pimAuthClaims.getProfile().displayName ?? ''}
        actions={[
          {
            icon: <LogOutIcon />,
            hierarchy: 'primary',
            text: t('common.logout'),
            callback: () => auth.signoutRedirect(),
          },
        ]}
      />
      <BpCard
        alwaysCollapsible
        isCollapsed={!editProfile}
        onCollapse={() => setEditProfile(true)}
        header={{
          headline: t('profiles.editProfile'),
        }}
        className='mb-6'
      >
        <ProfileForm profile={data?.profiles[0]} onClose={() => setEditProfile(false)} />
      </BpCard>

      <BpCard
        alwaysCollapsible
        isCollapsed={!editAccount}
        onCollapse={() => setEditAccount(true)}
        header={{
          headline: t('profiles.editAccount'),
        }}
        className='mb-6'
      >
        <AccountForm onClose={() => setEditAccount(false)} />
      </BpCard>

      <BpCard
        alwaysCollapsible
        isCollapsed={!editNotifications}
        onCollapse={() => setEditNotifications(true)}
        header={{
          headline: t('notifications.title'),
        }}
        className='mb-6'
      >
        <NotificationSettingsForm
          onClose={() => setEditNotifications(false)}
          data={
            profileSettings?.profiles[0]?.notificationPreferencesConnection.edges.map((e) => ({
              name: e.node.name ?? '',
              value: e.properties.transports as NotificationTypes[],
            })) ?? []
          }
        />
      </BpCard>
      {childProfilesClaim.length > 0 && perms?.canImpersonateChildProfile() && (
        <BpCard
          alwaysCollapsible
          isCollapsed={!editChildren}
          onCollapse={() => setEditChildren(true)}
          header={{
            headline: t('common.own-children'),
          }}
          className='mb-6'
        >
          <ChangeChildrenForm
            options={childrenOptions}
            hint={loginHint}
            onSetLoginHint={(value) => setLoginHint(value)}
            onChangeProfile={changeProfile}
            onClose={() => setEditChildren(false)}
          />
        </BpCard>
      )}
      <BpCard header={{ headline: t('common.accounts') }} alwaysCollapsible isCollapsed={true}>
        <AccessOverview combinedProfile={combinedProfile} />
      </BpCard>
    </BpPage>
  );
};
