import { Form, Formik, FormikHelpers } from 'formik';
import { Checkbox, Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import {
  useAbsenceReasonsQuery,
  useCreateAbsenceReasonMutation,
  useUpdateAbsenceReasonMutation,
} from '../../client/bp-graphql-client-defs';
import styles from './AbsenceReasonForm.module.scss';
import { useState } from 'react';
import { CombinedError } from 'urql';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';
import { absenceReasonsAsString } from '../../utils/absenceReasonAsString';

type AbsenceReasonFormProps = {
  selectedReasonUuid: string | null;
  onClose: () => void;
};

type AbsenceReasonFormType = {
  uuid: string;
  reason: string;
  isInternal: boolean;
};

export const AbsenceReasonForm = ({ selectedReasonUuid, onClose }: AbsenceReasonFormProps) => {
  const { t } = useTranslation();
  const context = useMemoizedCacheTag('ABSENCE_REASONS');
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const [loading, setLoading] = useState<boolean>(false);

  const [{ data }] = useAbsenceReasonsQuery({
    variables: { where: { organization: { uuid: organizationUuid } } },
    context,
  });

  const [, createReason] = useCreateAbsenceReasonMutation();
  const [, updateReason] = useUpdateAbsenceReasonMutation();

  const selectedReason = data?.absenceReasons.find((r) => r.uuid === selectedReasonUuid);

  const initialValues: AbsenceReasonFormType = {
    uuid: selectedReason?.uuid ?? '',
    reason: absenceReasonsAsString(selectedReason?.reason ?? ''),
    isInternal: selectedReason?.internal ?? false,
  };

  const handleSubmit = async (values: AbsenceReasonFormType, formHelpers: FormikHelpers<AbsenceReasonFormType>) => {
    let result: { error?: CombinedError | undefined } = {};
    setLoading(true);

    if (!selectedReasonUuid) {
      result = await createReason(
        {
          input: [
            {
              reason: values.reason,
              internal: values.isInternal,
              organization: { connect: { where: { node: { uuid: organizationUuid } } } },
            },
          ],
        },
        context,
      );
    } else {
      result = await updateReason(
        {
          where: { uuid: values.uuid },
          update: { reason: values.reason, internal: values.isInternal },
        },
        context,
      );
    }

    if (result.error) {
      showErrorToast(result.error);
    } else {
      if (selectedReasonUuid) {
        showSuccessToast(t('absenceReasons.reasonEdited'));
      } else {
        showSuccessToast(t('absenceReasons.reasonCreated'));
      }
    }

    setLoading(false);
    formHelpers.resetForm();
    onClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ resetForm, handleChange, values, isSubmitting, errors }) => {
        return (
          <Form className={styles['absence-reason-form']}>
            <Grid useFormGap>
              <GridRow>
                <GridColumn width={9}>
                  <Input
                    label={t('absenceReasons.title', { context: 'short' })}
                    onChange={handleChange}
                    name='reason'
                    value={values.reason}
                    error={errors.reason}
                  />
                </GridColumn>
                <GridColumn width={3} align='end'>
                  <Checkbox
                    className='mt-3'
                    name='isInternal'
                    checked={values.isInternal}
                    onChange={handleChange}
                    label={t('absenceReasons.isInternal')}
                  />
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  resetForm();
                  onClose();
                },
              }}
              submitButton={{ text: t('common.save') }}
              errors={errors}
              isLoading={isSubmitting || loading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
