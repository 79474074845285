import i18next from 'i18next';

enum AbsenceReasons {
  ILLNESS = 'illness',
  DOCTOR = 'doctor',
  ON_LEAVE = 'on_leave',
  OTHER = 'other',
}

export const absenceReasonsAsString = (reason: string) => {
  if (reason === AbsenceReasons.ILLNESS) {
    return i18next.t('absenceReasons.reason.illness');
  }

  if (reason === AbsenceReasons.DOCTOR) {
    return i18next.t('absenceReasons.reason.doctor');
  }
  if (reason === AbsenceReasons.ON_LEAVE) {
    return i18next.t('absenceReasons.reason.on_leave');
  }
  if (reason === AbsenceReasons.OTHER) {
    return i18next.t('absenceReasons.reason.other');
  }

  return reason;
};
