import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import { useContext } from 'react';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { useUpdateBpProfileMutation } from '../../client/bp-graphql-client-defs';
import styles from './ProfileForm.module.scss';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { CombinedProfile } from '../../pages/Profile/ProfilePage';
import { schema } from './validation/schema';

type ProfileFormType = CombinedProfile & {
  currentPassword: string;
  newPassword: string;
  newPasswordCopy: string;
};

type ProfileFormProps = {
  profile: CombinedProfile | undefined;
  onClose: () => void;
};

export const ProfileForm = ({ profile, onClose }: ProfileFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemoizedCacheTag('PROFILE');
  const { threemaLicense, threemaBroadcastLicense } = useContext(OrganizationConfigContext);

  const [, updateProfile] = useUpdateBpProfileMutation();

  const formikValues: ProfileFormType = {
    email: profile?.email ?? '',
    loginEmail: pimAuthClaims.getUser().email,
    threemaId: profile?.threemaId ?? '',
    firstName: profile?.firstName ?? '',
    lastName: profile?.lastName ?? '',
    currentPassword: '',
    newPassword: '',
    newPasswordCopy: '',
  };

  const handleSubmit = async (values: ProfileFormType) => {
    let closeModal: boolean = false;

    if (values.email !== formikValues.email) closeModal = await handleEmailUpdate(values);
    if (values.threemaId !== formikValues.threemaId) closeModal = await handleThreemaUpdate(values);
    if (closeModal && onClose) onClose();
  };

  const handleEmailUpdate = async (values: ProfileFormType) => {
    if (typeof values.email === 'string') {
      const { error } = await updateProfile(
        {
          update: {
            email: values.email,
          },
          uuid: pimAuthClaims.getProfile().uuid,
        },
        context,
      );

      error ? showErrorToast(error) : showSuccessToast(t('profiles.emailSaved'));
    }
    return true;
  };

  const handleThreemaUpdate = async (values: ProfileFormType) => {
    return true;
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={formikValues}
      validationSchema={schema}
      initialErrors={{ some: 'error' }} //  deactivates the submit button
    >
      {({ resetForm, isSubmitting, errors, handleChange, values, setFieldTouched, touched }) => {
        return (
          <Form className={styles['profile-form']}>
            <Grid>
              <GridRow mobileGap='var(--grid-column-gap)'>
                <GridColumn width={6}>
                  <Input
                    label={t('common.notificationEmail')}
                    onChange={handleChange}
                    name={'email'}
                    value={values.email}
                    type={'text'}
                    error={errors.email}
                  />

                  <Input
                    label={t('profiles.threemaId')}
                    onChange={handleChange}
                    name={'threemaId'}
                    value={values.threemaId ?? ''}
                    type={'text'}
                    error={errors.threemaId}
                    disabled={!threemaLicense && !threemaBroadcastLicense}
                  />
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              isLoading={isSubmitting}
              closeButton={{
                callback: () => {
                  resetForm();
                  if (onClose) onClose();
                },
              }}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
