import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { GroupType, useBpUpdateGroupsMutation, useGroupAsGroupsQuery } from '../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import styles from './GroupForm.module.scss';
import { GroupFormProfiles } from './GroupFormProfiles';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';

export type GroupFormOtherValues = {
  viewers: string[];
  admins: string[];
  editors: string[];
  classes?: string[] | null | undefined;
};

interface GroupsFormOtherProps {
  groupUuid: string;
  onClose: () => void;
  setTitle?: () => string;
}

export const GroupFormOther = ({ groupUuid, onClose }: GroupsFormOtherProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const [loading, setLoading] = useState(false);
  const context = useMemoizedCacheTag('GROUP');

  const [{ data }] = useGroupAsGroupsQuery({
    context,
    variables: {
      where: {
        uuid: groupUuid,
        type: GroupType.Group,
      },
    },
  });

  const [, bpUpdateGroup] = useBpUpdateGroupsMutation();

  const currentGroup = data?.groups[0];

  const handleSubmit = async (values: GroupFormOtherValues, formHelpers: FormikHelpers<GroupFormOtherValues>) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { admins, viewers } = values;

    const { error } = await bpUpdateGroup(
      {
        update: {
          viewers: viewers,
          admins: [pimAuthClaims.getProfile().uuid, ...admins],
          editors: currentGroup?.editors.map((e) => e.uuid) ?? [],
        },
        uuid: groupUuid,
      },
      context,
    );

    if (!error) {
      formHelpers.resetForm();
      onClose();
    }

    setLoading(false);
  };

  const initialValue: GroupFormOtherValues = {
    editors: currentGroup ? currentGroup.editors.map((value) => value.uuid) : [],
    admins: currentGroup ? currentGroup.admins.map((value) => value.uuid) : [pimAuthClaims.getProfile().uuid],
    viewers: currentGroup ? currentGroup.viewers.map((value) => value.uuid) : [],
  };

  return (
    <Formik<GroupFormOtherValues> onSubmit={handleSubmit} initialValues={initialValue}>
      {({ errors, isSubmitting }) => {
        return (
          <Form className={styles['group-form']}>
            <GroupFormProfiles<GroupFormOtherValues> />
            <ModalBottomButtons closeButton={{ callback: onClose }} isLoading={isSubmitting} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
