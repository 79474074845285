import {
  AddIcon,
  Button,
  ButtonGroup,
  DeleteIcon,
  Dropdown,
  DropdownMenu,
  EditIcon,
  Select,
  SelectOptionType,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { useBpAbsencesQuery } from 'client/bp-graphql-client-defs';
import dayjs from 'dayjs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import styles from './ClassbookAbsencesTable.module.scss';
import { useContext, useMemo, useState } from 'react';
import { usePermissionChecker } from 'hooks/usePermissionChecker';
import { OrganizationConfigContext } from 'context/OrganizationConfigContext';
import { AbsenceContext } from '../forms/ClassbookAbsencesForm';

type ClassbookAbsencesTableProps = {
  isLoading: boolean;
  context: AbsenceContext;
  onAdd: (context: AbsenceContext) => void;
  onEdit: (uuid: string, context: AbsenceContext) => void;
  onDelete: (uuid: string) => void;
};

type ClassbookAbsencesTableType = {
  uuid: string;
  name: string;
  reason: string;
  from: string;
  until: string;
  comment: string;
  isExcused: boolean;
  ownerUuid: string;
  context: AbsenceContext;
};

type FilterContextType = Omit<AbsenceContext, 'admin'> | 'all';

export const ClassbookAbsencesTable = ({
  isLoading,
  context,
  onAdd,
  onEdit,
  onDelete,
}: ClassbookAbsencesTableProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const { allowParentExcuses } = useContext(OrganizationConfigContext);
  const eventsContext = useMemoizedCacheTag('EVENT');

  const organization = pimAuthClaims.getOrganization();
  const profile = pimAuthClaims.getProfile();
  const children = pimAuthClaims.getChildProfiles();
  const isLegalAge = permissions?.canCreateAbsenceForSelf(organization, profile);

  const [filter, setFilter] = useState<FilterContextType>('all');

  const [{ data: absenceData }] = useBpAbsencesQuery({
    variables: {
      where: {
        owner: {
          uuid_IN: [profile.uuid, ...children.map((c) => c.uuid)],
        },
      },
    },
    context: eventsContext,
  });

  const filteredAbsences = useMemo(() => {
    const absences: ClassbookAbsencesTableType[] =
      absenceData?.absences.map((absence) => {
        const child = pimAuthClaims.getChildProfiles().find((child) => child.uuid === absence.owner.uuid);

        return {
          uuid: absence.uuid,
          name: child ? (child.displayName ?? '') : (profile.displayName ?? ''),
          reason: absence.reason?.reason ?? '',
          from: dayjs(absence.startTime).format('DD.MM.YY - HH:mm'),
          until: dayjs(absence.endTime).format('DD.MM.YY - HH:mm'),
          comment: absence.comment ?? '',
          isExcused: absence.excused ?? false,
          ownerUuid: absence.owner.uuid,
          context: child ? 'child' : 'myself',
        };
      }) ?? [];

    switch (filter) {
      case 'all':
        return absences;
      case 'child':
        return absences.filter((a) => a.ownerUuid !== profile.uuid);
      case 'myself':
        return absences.filter((a) => a.ownerUuid === profile.uuid);
      default:
        return absences;
    }
  }, [pimAuthClaims, absenceData, filter, profile.uuid, profile.displayName]);

  const columns: TableColumns<ClassbookAbsencesTableType>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: t('common.name'),
      size: 225,
    },
    {
      id: 'reason',
      accessorKey: 'reason',
      header: t('absenceReasons.title', { context: 'short' }),
      size: 175,
    },
    {
      id: 'from',
      accessorKey: 'from',
      header: t('common.from'),
    },
    {
      id: 'until',
      accessorKey: 'until',
      header: t('common.until'),
    },
    {
      id: 'comment',
      accessorKey: 'comment',
      header: t('common.comment'),
      size: 250,
    },
    {
      id: 'isExcused',
      accessorKey: 'isExcused',
      header: t('absences.isExcused'),
      type: 'boolean',
      size: 75,
    },
  ];

  const AddDropdown = (
    <Dropdown
      trigger={
        <Button hierarchy='tertiary' icon={<AddIcon />}>
          {t('common.add')}
        </Button>
      }
    >
      <DropdownMenu
        data={[
          {
            label: t('absences.forChild'),
            onClick: () => onAdd('child'),
            disabled: !permissions?.canCreateAbsenceForChildren(organization, children) || children.length === 0,
          },
          {
            label: t('absences.forMyself'),
            onClick: () => onAdd('myself'),
            disabled: !isLegalAge,
          },
        ]}
      />
    </Dropdown>
  );

  const FilterSelect = () => {
    const options: SelectOptionType[] = [
      { value: 'all', label: t('absences.filterAll') },
      { value: 'child', label: t('absences.filterChild') },
      { value: 'myself', label: t('absences.filterMine') },
    ];

    return (
      <div className={styles.filter}>
        <div className={styles.text}>{t('absences.filter')}:</div>
        <Select
          options={options}
          onChange={(e) => {
            setFilter((e as SingleValue<SelectOptionType>)?.value as FilterContextType);
          }}
          value={options.find((o) => o.value === filter)}
          name='filter'
          dense
          className={styles.select}
        />
      </div>
    );
  };

  return (
    <Table<ClassbookAbsencesTableType>
      className={styles['classbook-absences-table']}
      data={filteredAbsences}
      columns={columns}
      showBorderRadius
      showShadow
      isOnWhite={false}
      showActionBar
      actionBarSettings={{
        extendedActionsRight: (
          <>
            {FilterSelect()} {AddDropdown}
          </>
        ),
      }}
      lastColWidth='68px'
      lastCol={(row) => {
        const canExcuse =
          (row.original.context === 'myself' && isLegalAge) || (row.original.context === 'child' && allowParentExcuses);

        return (
          <ButtonGroup disabled={!canExcuse} tooltip={!canExcuse ? t('absences.forbidden') : undefined}>
            <Button
              hierarchy='secondary'
              type='button'
              onClick={() => onEdit(row.original.uuid, row.original.context)}
              icon={<EditIcon className='small' />}
            />
            <Button
              hierarchy='secondary'
              type='button'
              onClick={() => onDelete(row.original.uuid)}
              icon={<DeleteIcon className='small' color={canExcuse ? 'var(--color-error)' : undefined} />}
            />
          </ButtonGroup>
        );
      }}
      loading={isLoading}
    />
  );
};
