import { Modal } from '@bp/ui-components';
import { AbsenceContext, ClassbookAbsencesForm } from '../forms/ClassbookAbsencesForm';
import { useTranslation } from 'react-i18next';
import { lowercaseFirstLetter } from 'utils/stringHelper';

type ClassbookAbsencesModalProps = {
  isOpen: boolean;
  context: AbsenceContext;
  selectedAbsenceUuid: string | null;
  onClose: () => void;
};

export const ClassbookAbsencesModal = ({
  isOpen,
  context,
  selectedAbsenceUuid,
  onClose,
}: ClassbookAbsencesModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('common.addType', {
        type: `${t('absences.title', { count: 1 })} ${context !== 'admin' ? lowercaseFirstLetter(context === 'child' ? t('absences.forChild') : t('absences.forMyself')) : ''}`,
      })}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ClassbookAbsencesForm context={context} selectedAbsenceUuid={selectedAbsenceUuid} onClose={onClose} />
    </Modal>
  );
};
