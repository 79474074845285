import { useBpDeleteAbsenceMutation } from 'client/bp-graphql-client-defs';
import { BpSubpage } from 'components/BpSubpage/BpSubpage';
import { AbsenceContext } from 'components/Classbook/forms/ClassbookAbsencesForm';
import { ClassbookAbsencesModal } from 'components/Classbook/modals/ClassbookAbsencesModal';
import { ClassbookAbsencesTable } from 'components/Classbook/tables/ClassbookAbsencesTable';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useConfirm } from 'hooks/useConfirm';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { usePermissionChecker } from 'hooks/usePermissionChecker';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';

export const ClassbookAbsencesSubpage = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const eventsContext = useMemoizedCacheTag('EVENT');

  const organization = pimAuthClaims.getOrganization();
  const profile = pimAuthClaims.getProfile();
  const children = pimAuthClaims.getChildProfiles();

  const [selectedAbsenceUuid, setSelectedAbsenceUuid] = useState<string | null>(null);
  const [absencesContext, setAbsenceContext] = useState<AbsenceContext>('child');
  const [absencesModalOpen, setAbsencesModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [, deleteAbsence] = useBpDeleteAbsenceMutation();

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('absences.title', { count: 1 }), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  async function handleDelete(uuid: string) {
    setLoading(true);
    const result = await deleteAbsence({ where: { uuid } }, eventsContext);
    setLoading(false);
    if (result.error) {
      showErrorToast(result.error);
    } else {
      showSuccessToast(t('absences.absenceDeleted'));
    }
    setSelectedAbsenceUuid(null);
  }

  return (
    <BpSubpage
      isForbidden={
        !permissions?.canCreateAbsenceForChildren(organization, children) &&
        !permissions?.canCreateAbsenceForSelf(organization, profile)
      }
    >
      <ClassbookAbsencesTable
        isLoading={loading}
        context={absencesContext}
        onAdd={(context) => {
          setAbsenceContext(context);
          setAbsencesModalOpen(true);
        }}
        onEdit={(uuid, context) => {
          setSelectedAbsenceUuid(uuid);
          setAbsenceContext(context);
          setAbsencesModalOpen(true);
        }}
        onDelete={async (uuid) => {
          const res = await confirmDelete();
          if (res) handleDelete(uuid);
        }}
      />

      <ClassbookAbsencesModal
        isOpen={absencesModalOpen}
        context={absencesContext}
        selectedAbsenceUuid={selectedAbsenceUuid}
        onClose={() => {
          setAbsencesModalOpen(false);
          setSelectedAbsenceUuid(null);
        }}
      />
      <ConfirmationDialog />
    </BpSubpage>
  );
};
