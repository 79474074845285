import { memo, useContext, useState } from 'react';
import {
  ClassbookIcon,
  CoursesIcon,
  DashboardIcon,
  GroupsIcon,
  HomeIcon,
  LockIcon,
  MediathekIcon,
  MeetingDefaultIcon,
  ProfileIcon,
  SettingsIcon,
  Sidebar as UISidebar,
  TemplatesIcon,
  QuestionmarkCircleIcon,
} from '@bp/ui-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'context/AppContext';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import styles from './Sidebar.module.scss';
import { useWindowDimensions } from 'utils/dimensions';

type SidebarProps = {
  onFixed: (isOpen: boolean) => void;
};

export const Sidebar = memo(({ onFixed }: SidebarProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const location = useLocation();

  const { width } = useWindowDimensions();
  const showAbout = width < 770;

  const { sidebarIsOpen, setSidebarIsOpen } = useContext(AppContext);
  const [isFixed, setIsFixed] = useState<boolean>(false);

  const perms = usePermissionChecker();
  const organization = pimAuthClaims.getOrganization();
  const profile = pimAuthClaims.getProfile();
  const children = pimAuthClaims.getChildProfiles();

  return (
    <UISidebar
      currentLocationPath={location.pathname}
      logoType={'bildungsplattform'}
      sidebarContent={[
        {
          title: t('common.forMe'),
          items: [
            {
              icon: <DashboardIcon />,
              link: '',
              title: t(`dashboard.title`),
            },
            {
              icon: <CoursesIcon />,
              link: 'courses',
              title: t(`courses.title`),
            },
            {
              icon: <GroupsIcon />,
              link: 'groups',
              title: t(`groups.title`),
            },
            ...(perms?.canViewClassbook(organization, profile, children)
              ? [
                  {
                    icon: <ClassbookIcon />,
                    link: 'classbook',
                    title: t(`classbook.title`, { count: 1 }),
                  },
                ]
              : []),
            ...(perms?.canViewTemplates()
              ? [
                  {
                    icon: <TemplatesIcon />,
                    link: 'templates',
                    title: t(`templates.title`),
                  },
                ]
              : []),
          ],
        },
        ...(perms?.canViewMediaLibrary()
          ? [
              {
                title: 'Community',
                items: [
                  {
                    icon: <MediathekIcon />,
                    link: 'mediaLibrary',
                    title: t(`mediaLibrary.title`),
                  },
                ],
              },
            ]
          : []),

        {
          title: t('institution.general'),
          items: [
            {
              icon: <HomeIcon />,
              link: 'institution',
              title: t('institution.title'),
            },
            ...(perms?.canViewMeetings(organization)
              ? [
                  {
                    icon: <MeetingDefaultIcon />,
                    link: 'meetings',
                    title: t('meetings.titlesShort'),
                  },
                ]
              : []),
            ...(perms?.canViewSettings(organization)
              ? [
                  {
                    icon: <SettingsIcon />,
                    link: 'settings',
                    title: t('settings.title'),
                  },
                ]
              : []),
            ...(perms?.canViewAccounts()
              ? [
                  {
                    icon: <LockIcon />,
                    link: 'accounts',
                    title: t('accounts.title'),
                  },
                ]
              : []),
          ],
        },
      ]}
      sidebarFooterItems={[
        {
          icon: <ProfileIcon />,
          link: 'profile',
          title: t('profiles.titleSingular'),
        },
        ...(perms?.canViewAdmin()
          ? [
              {
                icon: <SettingsIcon />,
                link: 'admin',
                title: 'Administration',
              },
            ]
          : []),

        ...(showAbout
          ? [
              {
                icon: <QuestionmarkCircleIcon />,
                link: 'about',
                title: t('common.about'),
              },
            ]
          : []),
      ]}
      isExpanded={sidebarIsOpen}
      setIsExpanded={setSidebarIsOpen}
      isFixed={isFixed}
      setIsFixed={(value) => {
        setIsFixed(value);
        onFixed(value as boolean);
      }}
      className={styles.sidebar}
    />
  );
});

Sidebar.displayName = 'Sidebar';
