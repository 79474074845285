import { EmptyState, DivisionIllustration, Tooltip } from '@bp/ui-components';
import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useTranslation } from 'react-i18next';
import styles from './DivisionsList.module.scss';
import { useDivisionsQuery, useClassGroupsQuery } from 'client/bp-graphql-client-defs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { lowercaseFirstLetter } from 'utils/stringHelper';

type DivisionsListProps = {
  bpClass: BpClassType;
};

type DivisionsListType = {
  uuid: string;
  name: string;
  totalCount: number;
  groups: {
    uuid: string;
    name: string;
    shortName: string;
    studentCount: number;
  }[];
};

export const DivisionsList = ({ bpClass }: DivisionsListProps) => {
  const { t } = useTranslation();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();

  const divisionsContext = useMemoizedCacheTag('DIVISION');
  const classGroupsContext = useMemoizedCacheTag('CLASSGROUP');

  const [{ data: divisionsData }] = useDivisionsQuery({
    variables: { where: { class: { uuid: bpClass.uuid }, organization: { uuid: organizationUuid } } },
    context: divisionsContext,
  });

  const [{ data: groupsData }] = useClassGroupsQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
    context: classGroupsContext,
  });

  const divisions: DivisionsListType[] =
    divisionsData?.divisions.map((division) => {
      const groups = groupsData?.classGroups.filter((g) => g.division.uuid === division.uuid) ?? [];

      return {
        uuid: division.uuid,
        name: division.name,
        totalCount: groups.reduce((acc, curr) => {
          return acc + curr.membersConnection.edges.length;
        }, 0),
        groups:
          groups.map((g) => {
            return {
              uuid: g.uuid,
              name: g.name,
              shortName: g.shortName,
              studentCount: g.membersConnection.edges.length,
            };
          }) ?? [],
      };
    }) ?? [];

  return (
    <div className={styles['divisions-list']}>
      {divisions.length === 0 ? (
        <EmptyState
          borderRadius='none'
          size='small'
          icon={<DivisionIllustration />}
          title={t('divisions.noDivisions')}
        />
      ) : (
        divisions.map((division) => {
          const missing = bpClass.profileUuids.length - division.totalCount;

          return (
            <div key={division.uuid} className={styles['list-item']}>
              <div className={styles.groups}>
                {division.groups.map((group) => {
                  return (
                    <div
                      key={group.uuid}
                      className={styles.group}
                      style={{ flexBasis: `${100 / division.groups.length}%` }}
                    >
                      <Tooltip
                        content={`${group.name} - ${t('classes.studentCount', { count: group.studentCount })}`}
                        triggerClass={styles.name}
                      >
                        <div className={styles['short-name']}>{group.shortName}</div>
                        <div className={styles.count}>({group.studentCount})</div>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
              <div className={styles['division-name']}>
                <div className={styles.name}>{division.name}</div>
                <div className={styles.count}>
                  {missing > 0
                    ? t('classes.missingCount', { count: missing })
                    : `${division.totalCount} ${lowercaseFirstLetter(t('common.from'))} ${bpClass.profileUuids.length} ${lowercaseFirstLetter(t('classes.assigned'))}`}
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
