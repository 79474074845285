import { ProcuratPerson } from '../../Procurat/hooks/useProcuratGroups';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { Select, SelectOptionType } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileQueryType } from './ImportPersonsModal';
import { organizationRolesAsString } from '../../../utils/organizationRolesAsString';

export const AssignExistingProfile = ({
  person,
  handleSelect,
}: {
  person: ProcuratPerson & { matching?: Partial<ProfileQueryType>[] };
  handleSelect: (uuid: string) => void;
}) => {
  const { t } = useTranslation();
  const selectOpts = useCreateSelectOptions(
    person.matching?.map((e) => {
      const translatedRole = e.organizationRoles ? `${organizationRolesAsString(e.organizationRoles)}` : '';
      const selectName = `${e.selectName} - ${translatedRole}`;
      return {
        value: e.uuid,
        label: selectName,
      };
    }),
    'value',
    `label`,
  );

  return (
    <Select
      options={selectOpts}
      isSearchable={true}
      onChange={(event) => {
        const assign = (event as SingleValue<SelectOptionType>)?.value as string;
        handleSelect(assign);
      }}
      name={'assign'}
      placeholder={t('procuratImport.assignProfile')}
      dense
    />
  );
};
