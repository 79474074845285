import { BpCard } from 'components/BpCard/BpCard';
import { useTranslation } from 'react-i18next';
import { LazyLoader } from '@bp/ui-components';
import { Suspense, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  use_BpEventProfilesQuery,
  useBpAbsencesQuery,
  useBpCalendarEventsQuery,
  useBpCreateAbsenceMutation,
  useBpDeleteAbsenceMutation,
  useBpUpdateAbsenceMutation,
  useBpUpdateCalendarEventsMutation,
} from '../../../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../../../hooks/useMemoizedCacheTag';
import { BpTipTap } from '../../../BpTipTap/BpTipTap';
import { mapCalendarEventsToBpEventType } from '../../../../hooks/useCalendarEvents';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { ClassbookAttendeeList } from '../AttendeeList/ClassbookAttendeeList';
import { AbsenceType, Attendee } from '../AttendeeList/ClassbookAttendeeListItem';

export type ClassbookEventProps = {
  eventUuid: string | null;
};

export const ClassbookEvent = ({ eventUuid }: ClassbookEventProps) => {
  const { t } = useTranslation();

  const [noteCollapsed, setNoteCollapsed] = useState<boolean>(true);
  const [note, setNote] = useState<string | undefined>();

  const [homeworkCollapsed, setHomeworkCollapsed] = useState<boolean>(true);
  const [homework, setHomework] = useState<string | undefined>();

  const [updating, setUpdating] = useState<'note' | 'homework' | null>(null);

  const [, updateCalendarEvent] = useBpUpdateCalendarEventsMutation();
  const [, createAbsence] = useBpCreateAbsenceMutation();
  const [, updateAbsence] = useBpUpdateAbsenceMutation();
  const [, deleteAbsence] = useBpDeleteAbsenceMutation();

  const eventsContext = useMemoizedCacheTag('EVENT');

  const [{ data: eventData }] = useBpCalendarEventsQuery({
    variables: {
      where: {
        uuid: eventUuid,
      },
    },
    context: eventsContext,
    requestPolicy: 'network-only',
    pause: eventUuid === null,
  });

  const event = useMemo(() => {
    // since we are not interested in "owner"-field as boolean indicator, we can pass empty string as currentProfileUuid and false as isAdmin
    return mapCalendarEventsToBpEventType(eventData?.calendarEvents ?? [], '', false)?.find(
      (e) => e.uuid === eventUuid,
    );
  }, [eventUuid, eventData]);

  const duration = useMemo(() => {
    return dayjs.duration(Number(event?.duration), 's');
  }, [event]);

  const start = useMemo(() => {
    return dayjs(event?.start);
  }, [event]);

  const end = useMemo(() => {
    return dayjs(start).add(Number(duration));
  }, [start, duration]);

  const [{ data: absencesData }] = useBpAbsencesQuery({
    variables: {
      where: {
        owner: {
          uuid_IN: event?.attendees.map((a) => a.uuid),
        },
        OR: [
          { startTime_LTE: start, endTime_GTE: start },
          { startTime_GTE: end, endTime_LTE: end },
          { startTime_GTE: start, endTime_LTE: end },
        ],
      },
    },
    requestPolicy: 'network-only',
    pause: eventUuid === null,
    context: eventsContext,
  });

  const [{ data: profileData }] = use_BpEventProfilesQuery({
    variables: {
      where: {
        uuid_IN: event?.attendees.map((a) => a.uuid),
      },
    },
    context: useMemoizedCacheTag('PROFILE'),
    pause: eventUuid === null,
  });

  useEffect(() => {
    setNote(event?.classbookNote);
    setHomework(event?.classbookHomework);
  }, [event]);

  const attendees: Attendee[] = useMemo(() => {
    return (
      profileData?.profiles
        .filter((p) => p.organizationRoles.includes(ProfileRoles.Student))
        .sort((a, b) => {
          if (!a.lastName || !b.lastName) return 0;
          if (!a.firstName || !b.firstName) return 0;

          // First, compare by lastName
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;

          // If lastNames are equal, then compare by firstName
          if (a.firstName < b.firstName) return -1;
          if (a.firstName > b.firstName) return 1;

          // If both lastName and firstName are equal, return 0
          return 0;
        })
        .map((profile) => {
          const absenseForProfile =
            absencesData?.absences.find((absence) => {
              return absence.owner.uuid === profile.uuid;
            }) ?? null;

          return {
            uuid: profile.uuid,
            name: profile.firstName + ' ' + profile.lastName,
            comment: event?.attendees.find((a) => a.uuid === profile.uuid)?.classbookComment,
            absence: absenseForProfile
              ? ({
                  attendeeUuid: profile.uuid,
                  uuid: absenseForProfile.uuid,
                  completeAbsence: absenseForProfile.wholeDay ?? false,
                  absentFrom: absenseForProfile.startTime ?? dayjs(),
                  absentUntil: absenseForProfile.endTime ?? dayjs(),
                  absenceReason: absenseForProfile.reason?.uuid,
                  excused: absenseForProfile.excused,
                  status: absenseForProfile.excused === true ? 'excused' : 'absent',
                } as AbsenceType)
              : null,
          };
        }) ?? []
    );
  }, [absencesData, profileData, event]);

  async function handleEventSave(field: 'note' | 'homework') {
    if (event !== undefined && field === 'note') {
      setUpdating('note');
      await updateCalendarEvent({ where: { uuid: event?.uuid }, update: { classbookNote: note } }, eventsContext);
    }
    if (event !== undefined && field === 'homework') {
      setUpdating('homework');
      await updateCalendarEvent(
        { where: { uuid: event?.uuid }, update: { classbookHomework: homework } },
        eventsContext,
      );
    }
    setUpdating(null);
  }

  async function onAbsenceUpdate(updatedAbsence: Partial<AbsenceType> & { comment?: string }) {
    let connectAbsence = {};
    if (updatedAbsence.absenceReason) {
      connectAbsence = { connect: { where: { node: { uuid: updatedAbsence.absenceReason } } } };
    }

    if (updatedAbsence.uuid && updatedAbsence.status === 'present') {
      await deleteAbsence({ where: { uuid: updatedAbsence.uuid } }, eventsContext);
    } else if (
      updatedAbsence.uuid === undefined &&
      updatedAbsence.status !== undefined &&
      updatedAbsence.status !== 'present'
    ) {
      await createAbsence(
        {
          input: {
            foreignRefId: [],
            startTime: start,
            endTime: end,
            excused: updatedAbsence.excused ?? false,
            reason: { ...connectAbsence },
            owner: {
              connect: {
                where: {
                  node: {
                    uuid: updatedAbsence.attendeeUuid,
                  },
                },
              },
            },
          },
        },
        eventsContext,
      );
    } else if (updatedAbsence.uuid !== undefined) {
      await updateAbsence(
        {
          where: {
            uuid: updatedAbsence.uuid,
          },
          update: {
            startTime: updatedAbsence.absentFrom,
            endTime: updatedAbsence.absentUntil,
            excused: updatedAbsence.excused ?? false,
            reason: { disconnect: { where: {} }, ...connectAbsence },
          },
        },
        eventsContext,
      );
    }

    if (updatedAbsence.comment !== undefined && event?.uuid && updatedAbsence.attendeeUuid) {
      if (updatedAbsence.comment === '{"type":"doc","content":[{"type":"paragraph"}]}') {
        updatedAbsence.comment = '';
      }
      await updateCalendarEvent(
        {
          where: { uuid: event?.uuid },
          update: {
            attendees: [
              {
                connect: [
                  {
                    overwrite: true,
                    where: { node: { uuid: updatedAbsence.attendeeUuid } },
                    edge: { classbookComment: updatedAbsence.comment },
                  },
                ],
              },
            ],
          },
        },
        eventsContext,
      );
    }
  }

  return (
    <>
      <BpCard
        className='mb-7'
        alwaysCollapsible
        disableCollapsible={!event}
        isCollapsed={noteCollapsed}
        onCollapse={() => setNoteCollapsed(!noteCollapsed)}
        header={{
          headline: event ? t('notes.titleFor') : t('notes.title'),
          subHeadline: event?.title,
          showSubHeadlineAsChip: true,
        }}
      >
        <BpTipTap
          disabled={updating === 'note'}
          loading={updating === 'note'}
          key={event?.uuid}
          defaultValue={event?.classbookNote ?? ''}
          onChange={setNote}
          onBlur={(e) => {
            setNote(e);
            handleEventSave('note');
          }}
          name='note'
          isDense
        />
      </BpCard>
      <BpCard
        className='mb-7'
        alwaysCollapsible
        disableCollapsible={!event}
        isCollapsed={homeworkCollapsed}
        onCollapse={() => setHomeworkCollapsed(!homeworkCollapsed)}
        header={{
          headline: t('common.homework'),
        }}
      >
        <BpTipTap
          disabled={updating === 'homework'}
          loading={updating === 'homework'}
          key={event?.uuid}
          defaultValue={event?.classbookHomework ?? ''}
          onChange={setHomework}
          onBlur={(e) => {
            setHomework(e);
            handleEventSave('homework');
          }}
          name='homework'
          isDense
        />
      </BpCard>
      <BpCard
        noPadding
        alwaysCollapsible
        disableCollapsible={!event}
        isCollapsed={!event}
        header={{
          headline: t('students.title'),
          subHeadline: event
            ? t('classbook.attendantHint', {
                actual: attendees.filter((attendee) => attendee.absence === null).length,
                desired: attendees.length,
              })
            : undefined,
        }}
      >
        <Suspense fallback={<LazyLoader embedded size='xxl' forceHeight='20vh' />}>
          <ClassbookAttendeeList
            key={event?.uuid}
            attendees={attendees}
            event={event ?? null}
            onAbsenceUpdate={onAbsenceUpdate}
          />
        </Suspense>
      </BpCard>
    </>
  );
};
