import { Modal } from '@bp/ui-components';
import { AbsenceReasonForm } from 'components/AbsenceReasonForm/AbsenceReasonForm';
import { useTranslation } from 'react-i18next';

type AbsenceReasonModalProps = {
  isOpen: boolean;
  selectedReasonUuid: string | null;
  onClose: () => void;
};

export const AbsenceReasonModal = ({ isOpen, selectedReasonUuid, onClose }: AbsenceReasonModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={t('common.addType', { type: t('absenceReasons.title', { count: 1 }) })}
      width='s'
    >
      <AbsenceReasonForm selectedReasonUuid={selectedReasonUuid} onClose={onClose} />
    </Modal>
  );
};
