import { ProfileSelection, SelectorType } from 'components/ProfileSelection/ProfileSelection';
import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import styles from './ClassProfilesForm.module.scss';
import { useBpMinimalProfileQuery, useUpdateClassMutation } from 'client/bp-graphql-client-defs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { useTranslation } from 'react-i18next';
import { ModalBottomButtons } from '@bp/ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import { CombinedError } from 'urql';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';
import { OrganizationConfigContext } from 'context/OrganizationConfigContext';
import { useContext } from 'react';

type ClassProfilesFormProps = {
  bpClass: BpClassType;
  onLoad: (isLoading: boolean) => void;
  onClose: () => void;
};

type ClassesProfileFormType = {
  profiles: SelectorType[];
};

export const ClassProfilesForm = ({ bpClass, onLoad, onClose }: ClassProfilesFormProps) => {
  const { t } = useTranslation();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();
  const { currentSchoolYear } = useContext(OrganizationConfigContext);

  const profileContext = useMemoizedCacheTag('PROFILE');
  const classContext = useMemoizedCacheTag('CLASS');

  const [, updateClass] = useUpdateClassMutation();

  const [{ data: profileData }] = useBpMinimalProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
    context: profileContext,
  });

  const initialValues: ClassesProfileFormType = {
    profiles: bpClass.profileUuids.map((uuid) => {
      const profile = profileData?.profiles.find((profile) => profile.uuid === uuid);
      return {
        label: profile?.displayName ?? '',
        value: profile?.uuid ?? '',
      };
    }),
  };

  let selectableProfiles: SelectorType[] =
    profileData?.profiles
      .filter((profile) => !bpClass.profileUuids.some((uuid) => uuid === profile.uuid))
      .map((profile) => {
        return { value: profile.uuid, label: profile.displayName ?? '' };
      }) ?? [];

  const handleSubmit = async (values: ClassesProfileFormType, formHelpers: FormikHelpers<ClassesProfileFormType>) => {
    onLoad(true);

    let result: { error?: CombinedError | undefined } = {};

    const initialProfilesUuids = bpClass.profileUuids;
    const newProfilesUuids = values.profiles.map((p) => p.value);

    const profilesToConnect = newProfilesUuids.filter(
      (newProfile) => !initialProfilesUuids.some((initialProfile) => initialProfile === newProfile),
    );

    const profilesToDisconnect = initialProfilesUuids.filter(
      (initialProfile) => !newProfilesUuids.some((newProfile) => initialProfile === newProfile),
    );

    result = await updateClass(
      {
        update: {
          schoolYear: { connect: { where: { node: { uuid: currentSchoolYear.uuid } } } },
          members: [
            {
              connect: profilesToConnect.map((uuid) => {
                return {
                  where: {
                    node: {
                      uuid: uuid,
                    },
                  },
                };
              }),
              disconnect: profilesToDisconnect.map((uuid) => {
                return {
                  where: {
                    node: {
                      uuid: uuid,
                    },
                  },
                };
              }),
            },
          ],
        },
        where: {
          uuid: bpClass.uuid,
        },
      },
      classContext,
    );

    if (result.error) {
      showErrorToast(result.error);
    } else {
      showSuccessToast(t('classes.profilesEdited', { name: bpClass.name }));
    }

    onLoad(false);
    formHelpers.resetForm();
    onClose();
  };

  return (
    <Formik<ClassesProfileFormType>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateOnBlur={true}
      enableReinitialize={true}
    >
      {({ errors, values, setFieldValue, isSubmitting, dirty, isValidating }) => {
        return (
          <Form className={styles['class-profiles-form']}>
            <ProfileSelection
              items={selectableProfiles}
              selectedItems={values.profiles}
              maxHeight='430px'
              onSelect={(items) => {
                setFieldValue('profiles', [...values.profiles, ...items]);
                selectableProfiles = selectableProfiles.filter(
                  (profile) => !items.some((item) => item.value === profile.value),
                );
              }}
              onRemove={(items) => {
                setFieldValue('profiles', [
                  ...values.profiles.filter((profile) => !items.some((item) => item.value === profile.value)),
                ]);
              }}
              className={styles['profile-selection']}
            />

            <ModalBottomButtons
              closeButton={{
                callback: onClose,
              }}
              submitButton={{
                disabled: isSubmitting || !dirty || isValidating,
              }}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
