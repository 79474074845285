import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import { useState } from 'react';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { useUpdatePasswordsMutation } from '../../client/bp-graphql-client-defs';
import PasswordStrengthBar from 'react-password-strength-bar';
import styles from './ProfileForm.module.scss';
import { CombinedProfile } from '../../pages/Profile/ProfilePage';
import { EyeSymbol } from './EyeSymbol';
import { schema } from './validation/schema';

type AccountFormType = CombinedProfile & {
  currentPassword: string;
  newPassword: string;
  newPasswordCopy: string;
};

type AccountFormProps = {
  onClose: () => void;
};

export const AccountForm = ({ onClose }: AccountFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();

  const [, updatePassword] = useUpdatePasswordsMutation();
  const [oldPwVisible, setOldPwVisible] = useState(false);
  const [newPwVisible, setNewPwVisible] = useState(false);
  const [passwordValidates, setPasswordValidates] = useState(true);

  const formikValues: AccountFormType = {
    loginEmail: pimAuthClaims.getUser().email,
    currentPassword: '',
    newPassword: '',
    newPasswordCopy: '',
  };

  const handleSubmit = async (values: AccountFormType) => {
    if (values.currentPassword !== '' && values.newPassword !== '' && values.newPasswordCopy === values.newPassword) {
      const { error } = await updatePassword({
        where: {
          uuid: pimAuthClaims.getUser().uuid, // here we change the USER
        },
        update: {
          newPassword: values.newPassword,
          oldPassword: values.currentPassword,
        },
      });

      setPasswordValidates(!error);

      if (!error) {
        showSuccessToast(t('profiles.passwordSaved'));
        onClose();
      }
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={formikValues}
      validationSchema={schema}
      initialErrors={{ some: 'error' }} //  deactivates the submit button
    >
      {({ resetForm, isSubmitting, errors, handleChange, values, setFieldTouched, touched }) => {
        return (
          <Form className={styles['profile-form']}>
            <Grid>
              <GridRow mobileGap='var(--grid-column-gap)'>
                <GridColumn width={6}>
                  <Input
                    label={t('profiles.loginName')}
                    onChange={handleChange}
                    name={'loginName'}
                    value={values.loginEmail}
                    type={'text'}
                    readonly
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Input
                    label={t('profiles.currentPassword')}
                    onChange={async (ev) => {
                      setPasswordValidates(true);
                      handleChange(ev);
                      await setFieldTouched('currentPassword');
                    }}
                    name={'currentPassword'}
                    value={values.currentPassword}
                    type={oldPwVisible ? 'text' : 'password'}
                    suffix={<EyeSymbol visible={oldPwVisible} toggle={() => setOldPwVisible((prev) => !prev)} />}
                  />
                  <Input
                    label={t('profiles.newPassword')}
                    onChange={async (ev) => {
                      handleChange(ev);
                      await setFieldTouched('newPassword');
                    }}
                    name={'newPassword'}
                    value={values.newPassword}
                    error={errors.newPassword}
                    type={newPwVisible ? 'text' : 'password'}
                    disabled={!touched.currentPassword}
                    suffix={<EyeSymbol visible={newPwVisible} toggle={() => setNewPwVisible((prev) => !prev)} />}
                  />
                  <Input
                    label={t('profiles.newPasswordRepeat')}
                    onChange={handleChange}
                    name={'newPasswordCopy'}
                    value={values.newPasswordCopy}
                    error={errors.newPasswordCopy}
                    type={newPwVisible ? 'text' : 'password'}
                    disabled={!touched.newPassword}
                    suffix={<EyeSymbol visible={newPwVisible} toggle={() => setNewPwVisible((prev) => !prev)} />}
                  />
                  <div className={styles['strength-bar-wrapper']}>
                    <PasswordStrengthBar
                      password={values.newPassword}
                      minLength={8}
                      scoreWords={[
                        t('profiles.weak'),
                        t('profiles.weak'),
                        t('profiles.okay'),
                        t('profiles.good'),
                        t('profiles.strong'),
                      ]}
                      shortScoreWord={t('validation.passwordsMinLength')}
                    />
                    {
                      // to make this special error more clearly visible I put it here
                      !passwordValidates && <div className={styles.error}>{t('validation.passwordWrong')}</div>
                    }
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              isLoading={isSubmitting}
              closeButton={{
                callback: () => {
                  resetForm();
                  if (onClose) onClose();
                },
              }}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
