import {
  Checkbox,
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  ModalBottomButtons,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import {
  useAbsenceReasonsQuery,
  useBpAbsencesQuery,
  useBpCreateAbsenceMutation,
  useBpMinimalProfileQuery,
  useBpUpdateAbsenceMutation,
  useClassesQuery,
} from 'client/bp-graphql-client-defs';
import { OrganizationConfigContext } from 'context/OrganizationConfigContext';
import dayjs from 'dayjs';
import { Form, Formik, FormikHelpers } from 'formik';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useCreateSelectOptions } from 'hooks/useCreateSelectOptions';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { usePermissionChecker } from 'hooks/usePermissionChecker';
import { ChangeEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CombinedError } from 'urql';
import { isAfter, isBefore, setTimeOnDate } from 'utils/dateCalculations';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';

export type AbsenceContext = 'child' | 'myself' | 'admin';

type ClassbookAbsencesFormProps = {
  context: AbsenceContext;
  selectedAbsenceUuid: string | null;
  onClose: () => void;
};

type ClassbookAbsencesFormType = {
  ownerUuid: string;
  reasonUuid: string;
  start: Date;
  end: Date;
  startTimeError: string; // only used for error messages
  endTimeError: string; // only used for error messages
  comment: string;
  isExcused: boolean;
};

export const ClassbookAbsencesForm = ({ selectedAbsenceUuid, context, onClose }: ClassbookAbsencesFormProps) => {
  const { t } = useTranslation();
  const { currentSchoolYear, allowParentExcuses } = useContext(OrganizationConfigContext);
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const reasonsContext = useMemoizedCacheTag('ABSENCE_REASONS');
  const eventsContext = useMemoizedCacheTag('EVENT');
  const profileContext = useMemoizedCacheTag('PROFILE');
  const classesContext = useMemoizedCacheTag('CLASS');

  const [loading, setLoading] = useState<boolean>(false);

  const [, createAbsence] = useBpCreateAbsenceMutation();
  const [, updateAbsence] = useBpUpdateAbsenceMutation();

  const organization = pimAuthClaims.getOrganization();
  const profile = pimAuthClaims.getProfile();
  const children = pimAuthClaims.getChildProfiles();

  const isLegalAge = permissions?.canCreateAbsenceForSelf(organization, profile);
  const canExcuse =
    context === 'admin' || (context === 'myself' && isLegalAge) || (context === 'child' && allowParentExcuses);

  const [{ data: absencesData }] = useBpAbsencesQuery({
    variables: {
      where: {
        owner: {
          uuid_IN: [pimAuthClaims.getProfile().uuid, ...children.map((c) => c.uuid)],
        },
      },
    },
    context: eventsContext,
  });

  const [{ data: reasonsData }] = useAbsenceReasonsQuery({
    variables: {
      where: {
        organization: { uuid: pimAuthClaims.getOrganizationUuid() },
        AND: [{ internal: false }],
      },
    },
    context: reasonsContext,
  });

  const [{ data: profileData }] = useBpMinimalProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: organization.uuid,
        },
      },
    },
    context: profileContext,
  });

  const [{ data: classesData }] = useClassesQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: classesContext,
  });

  const absence = absencesData?.absences.find((absence) => absence.uuid === selectedAbsenceUuid);

  const childOptions = useCreateSelectOptions(children, 'uuid', 'displayName');
  const reasonOptions = useCreateSelectOptions(reasonsData?.absenceReasons, 'uuid', 'reason');

  const adminOptions: SelectOptionType[] = useMemo(() => {
    const options: SelectOptionType[] = [];
    const profiles = profileData?.profiles.filter((p) => p.organizationRoles.includes('STUDENT'));

    profiles?.forEach((p) => {
      classesData?.classes.forEach((c) => {
        if (c.membersConnection.edges.some((e) => e.node.uuid === p.uuid)) {
          options.push({
            value: p.uuid,
            label: p.displayName ?? '',
            group: c.name,
          });
        }
      });
    });

    return options.sort((a, b) => {
      if (a.group && b.group) {
        return a.group.localeCompare(b.group, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      } else {
        return 1;
      }
    });
  }, [classesData?.classes, profileData?.profiles]);

  const initialValues: ClassbookAbsencesFormType = {
    ownerUuid: absence
      ? absence.owner.uuid
      : context === 'child' && children.length === 1
        ? (childOptions[0].value as string)
        : '',
    reasonUuid: absence?.reason?.uuid ?? '',
    start: absence?.startTime ?? dayjs().startOf('day').toDate(),
    end: absence?.endTime ?? dayjs().endOf('day').toDate(),
    startTimeError: '',
    endTimeError: '',
    comment: absence?.comment ?? '',
    isExcused: absence?.excused ?? canExcuse,
  };

  async function handleSubmit(
    values: ClassbookAbsencesFormType,
    formHelpers: FormikHelpers<ClassbookAbsencesFormType>,
  ) {
    let hasErrors = false;
    if ((context === 'admin' || (context === 'child' && children.length > 0)) && !values.ownerUuid) {
      formHelpers.setFieldError('ownerUuid', t('validation.required.profile'));
      hasErrors = true;
    }
    if (!values.reasonUuid) {
      formHelpers.setFieldError('reasonUuid', t('validation.required.reason'));
      hasErrors = true;
    }
    if (hasErrors) return;

    const ownerUuid = context === 'myself' ? profile.uuid : values.ownerUuid;

    const connectAbsence = { connect: { where: { node: { uuid: values.reasonUuid } } } };
    const connectOwner = { connect: { where: { node: { uuid: ownerUuid } } } };

    let result: { error?: CombinedError | undefined } = {};
    setLoading(true);

    if (!selectedAbsenceUuid) {
      result = await createAbsence(
        {
          input: {
            foreignRefId: [],
            startTime: values.start,
            endTime: values.end,
            excused: values.isExcused,
            reason: connectAbsence,
            comment: values.comment,
            owner: connectOwner,
          },
        },
        eventsContext,
      );
    } else {
      result = await updateAbsence(
        {
          where: {
            uuid: selectedAbsenceUuid,
          },
          update: {
            startTime: values.start,
            endTime: values.end,
            excused: values.isExcused,
            reason: {
              disconnect: {},
              ...connectAbsence,
            },
            comment: values.comment,
          },
        },
        eventsContext,
      );
    }

    if (result.error) {
      showErrorToast(result.error);
    } else {
      if (selectedAbsenceUuid) {
        showSuccessToast(t('absences.absenceEdited'));
      } else {
        showSuccessToast(t('absences.absenceCreated'));
      }
    }

    setLoading(false);
    formHelpers.resetForm();
    onClose();
  }

  return (
    <Formik<ClassbookAbsencesFormType> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue, values, isSubmitting, dirty, resetForm, errors, setFieldError, setErrors }) => {
        return (
          <Form>
            <Grid useFormGap>
              <GridRow>
                <GridColumn width={10}>
                  <GridRow spacingBottom='s'>
                    {context === 'child' && (
                      <GridColumn width={6}>
                        <Select
                          label={t('absences.forChild')}
                          isSearchable
                          options={childOptions}
                          onChange={async (e) => {
                            const childUuid = e && ((e as SelectOptionType).value as number);
                            await setFieldValue('ownerUuid', childUuid);
                          }}
                          value={childOptions.find((c) => c.value === values.ownerUuid)}
                          readonly={children.length === 1}
                          disabled={children.length === 0}
                          name='child'
                          error={errors.ownerUuid}
                        />
                      </GridColumn>
                    )}
                    {context === 'admin' && (
                      <GridColumn width={6}>
                        <Select
                          label={t('absences.forStudent')}
                          isSearchable
                          hasGroups
                          options={adminOptions}
                          onChange={async (e) => {
                            const profileUuid = e && ((e as SelectOptionType).value as number);
                            await setFieldValue('ownerUuid', profileUuid);
                          }}
                          value={adminOptions.find((c) => c.value === values.ownerUuid)}
                          name='profile'
                          error={errors.ownerUuid}
                        />
                      </GridColumn>
                    )}
                    <GridColumn width={context === 'myself' ? 12 : 6}>
                      <Select
                        label={t('absenceReasons.title', { context: 'short' })}
                        options={reasonOptions}
                        onChange={async (e) => {
                          const reasonUuid = e && ((e as SelectOptionType).value as number);
                          await setFieldValue('reasonUuid', reasonUuid);
                        }}
                        value={reasonOptions.find((r) => r.value === values.reasonUuid)}
                        name='reason'
                        error={errors.reasonUuid}
                      />
                    </GridColumn>
                  </GridRow>
                  <GridRow spacingTop='s' spacingBottom='s'>
                    <GridColumn width={3}>
                      <DatePicker
                        name='start-date'
                        value={values.start}
                        label={t('common.from')}
                        onChange={async (e) => {
                          await setFieldValue('start', e, false);
                          if (isAfter(e, values.end)) {
                            setFieldError('start', t('validation.time.startDateAfterEnd'));
                          } else if (isAfter(e, values.end, 'minutes')) {
                            setFieldError('startTimeError', t('validation.time.startTimeAfterEnd'));
                          } else {
                            setFieldError('start', undefined);
                            setFieldError('startTimeError', undefined);
                          }
                        }}
                        minDate={dayjs(currentSchoolYear?.start).toDate()}
                        maxDate={dayjs(currentSchoolYear?.end).toDate()}
                        error={errors.start as string}
                      />
                    </GridColumn>
                    <GridColumn width={3}>
                      <Input
                        name='start-time'
                        label={t('common.start')}
                        value={dayjs(values.start).format('HH:mm')}
                        onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                          const time = setTimeOnDate(values.start, e.target.value);
                          await setFieldValue('start', time, false);
                          if (isAfter(time, values.end, 'minutes')) {
                            setFieldError('startTimeError', t('validation.time.startTimeAfterEnd'));
                          } else if (isAfter(time, values.end)) {
                            setFieldError('start', t('validation.time.startDateAfterEnd'));
                          } else {
                            setFieldError('startTimeError', undefined);
                            setFieldError('start', undefined);
                          }
                        }}
                        error={errors.startTimeError as string}
                        type='time'
                      />
                    </GridColumn>
                    <GridColumn width={3}>
                      <DatePicker
                        name='end-date'
                        value={values.end}
                        label={t('common.until')}
                        onChange={async (e) => {
                          await setFieldValue('end', e);
                          if (isBefore(e, values.start)) {
                            setFieldError('end', t('validation.time.endDateBeforeStart'));
                          } else if (isBefore(e, values.start, 'minutes')) {
                            setFieldError('endTimeError', t('validation.time.endTimeBeforeEnd'));
                          } else {
                            setFieldError('end', undefined);
                            setFieldError('endTimeError', undefined);
                          }
                        }}
                        minDate={dayjs(currentSchoolYear?.start).toDate()}
                        maxDate={dayjs(currentSchoolYear?.end).toDate()}
                        error={errors.end as string}
                      />
                    </GridColumn>
                    <GridColumn width={3}>
                      <Input
                        name='end-time'
                        label={t('common.end')}
                        value={dayjs(values.end).format('HH:mm')}
                        onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                          const time = setTimeOnDate(values.end, event.target.value);
                          await setFieldValue('end', time);
                          if (isBefore(time, values.start, 'minutes')) {
                            setFieldError('endTimeError', t('validation.time.endTimeBeforeEnd'));
                          } else if (isBefore(time, values.start)) {
                            setFieldError('end', t('validation.time.endDateBeforeStart'));
                          } else {
                            setFieldError('endTimeError', undefined);
                            setFieldError('end', undefined);
                          }
                        }}
                        error={errors.endTimeError as string}
                        type='time'
                      />
                    </GridColumn>
                  </GridRow>
                  <GridRow spacingTop='s'>
                    <Input
                      label={t('common.comment')}
                      name='comment'
                      value={values.comment}
                      onChange={async (e) => await setFieldValue('comment', e.target.value)}
                    />
                  </GridRow>
                </GridColumn>
                <GridColumn width={2} align='end'>
                  <Checkbox
                    className='mt-3'
                    label={t('absences.isExcused')}
                    name='is-excused'
                    onChange={async (e) => await setFieldValue('isExcused', e.target.checked)}
                    checked={values.isExcused}
                    disabled={!canExcuse}
                  />
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  onClose();
                  resetForm();
                },
              }}
              isLoading={loading}
              submitButton={{
                disabled: isSubmitting || !dirty,
              }}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
